<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section19" class="SearchSigningProcesses sectionContainer ">
      <h1 class="section-title">Search signing processes</h1>
      <div class="d-flex version-switch mt-4">
        <span>v3</span>
        <v-switch v-model="isV4" class="mx-2"></v-switch>
        <span>v4</span>
      </div>
      <p><b>Scope:</b><i> basic</i></p>
      <hr class="line-break">
      <p>You can retrieve all your signing processes or search them based on their status.</p>
      <v-card>
        <div class="card-body mb-4">
<pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/signatures HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "retrieve",
     "data_type": "signature_flows",
     "trans_map": {
         "<span v-if="isV4">{{ versionDifferenceAccessToken }}</span><span
      v-if="!isV4">{{ versionDifferenceAuthKey }}</span>": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
     },
     "request_map": {
         "status": "pending",
         "scope": "user",
         "limit": 1
     }
 }
</code></pre>
        </div>
      </v-card>
      <p>The parameters in the request map:</p>
      <v-simple-table light class="mb-4">
        <template v-slot:default>
          <tbody>
          <tr>
            <td>status</td>
            <td>Filter results based on the status field. Accepted values are pending,
              completed and canceled.
            </td>
          </tr>
          <tr>
            <td>scope</td>
            <td>Constrain search results to only processes created by the user. Accepted
              values are user and group. The user has to have admin rights to use the scope <b>group</b>.
              Defaults to <b>user</b>.
            </td>
          </tr>
          <tr>
            <td>limit</td>
            <td>Number of results to return in a single response. The maximum number of results is 1000. Defaults
              to 10.
            </td>
          </tr>
          <tr>
            <td>cursor</td>
            <td>Cursor to retrieve the next set of results.</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card>
        <div class="card-body mb-4">
<pre><code>{
    "action_str": "retrieved",
    "data_type": "signature_flows",
    "log_list": [
        {
            "code_key": <span v-if="!isV4">{{ versionDifferenceQuotationMark200 }}</span><span v-if="isV4">{{ versionDifference200 }}</span>,
            "code_str": "ok",
            "user_msg": "signature_flows retrieved",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "trans_map": {<span v-if="!isV4">
        "auth_key": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
    </span>},
    "response_map": {
        "signature_flows": [
            {
                "uuid" : "d7e93169-08bb-11e7-a142-080027334cc4",
                "title": "My first",
                "created": 1488799050744,
                "modified": 1488799050744,
                "status": "pending",
                "pending_count": 1,
                "locale": "en"
            }
        ],
        "cursor": "OikKAfqAMqMjU20wSDdHlwZUtzaWduX3Byb2Nlc3P7gDHS-w=="
    }
}
</code></pre>
        </div>
      </v-card>
      <p>The parameters in the response map:</p>
      <v-simple-table light>
        <template v-slot:default>
          <tbody>
          <tr>
            <td>cursor</td>
            <td>Cursor to retrieve the next set of results, in case the number of results is more than
              limit value.
            </td>
          </tr>
          <tr>
            <td>signature_flows</td>
            <td>Found signing processes.</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

    </div>
  </div>
</template>

<script>
    export default {
        name : "SearchSigningProcess",
        data()
        {
            return {
                isV4 : true,
                versionDifferenceSlash4 : '/4',
                versionDifference200 : '200',
                versionDifferenceQuotationMark200 : '"200"',
                versionDifferenceAuthKey : 'auth_key',
                versionDifferenceAccessToken : 'access_token',
            }
        }
    }
</script>

<style scoped>

</style>
