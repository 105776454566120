/**
 * Service schema for user (signed-in) client.
 *
 * @module
 */
export default {
    interface_version : "1.0.0",
    retrieve : {
        sessions : {
        }
    },
    update : {
        profile : {
            requestMapSchema : {
                type : "object",
                properties : {
                    payload : {
                        locale : {
                            type : "string",
                        },
                        name : {
                            type : "string"
                        }
                    }
                },
                additionalProperties : false,
                required : [],
                handler : "route"
            }
        }
    }
};
