<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section8" class="RequestAuthorisationCode sectionContainer">
      <h1 class="section-title">Request an authorisation code</h1>
      <hr class="line-break">
      <p>
        Your application should redirect the user to the authorisation endpoint which will display a consent page.
      </p>
      <div class="screenshotContainer">
        <img class="img-fluid" src="/api-document-images/consentPage.png">
      </div>
      <p>The authorisation URL should have the following format:</p>
      <v-card class="mb-4">
        <div class="card-body">
          <pre><code>{{ authorisationURL }}</code></pre>
        </div>
      </v-card>
      <p>The parameters in the query:</p>
      <v-simple-table light>
        <template v-slot:default>
          <tbody>
          <tr>
            <td>client_id</td>
            <td>Your application's client identifier.</td>
          </tr>
          <tr>
            <td>redirect_uri</td>
            <td>The URI to which SignHero will redirect the user after authorisation.
              This URI must be specified as a valid redirect URI in your application
              configurations.
            </td>
          </tr>
          <tr>
            <td>response_type</td>
            <td>The value must be "code".</td>
          </tr>
          <tr>
            <td>scope</td>
            <td class="py-4">Space-delimited list of API scopes requested from a user. URL-encoded space character is either
              <i>+</i> or <i>%20</i>. Available scopes:<br><br>
              <ul>
                <li><b>basic</b>: Create signing processes and manage them. Default scope.</li>
                <li><b>profile</b>: <v-btn text class="api-positive-link px-0" to="/docs/api/retrieve-profile-info">Retrieve
                  user profile</v-btn> information.
                </li>
                <li><b>signing_url</b>: <v-btn text class="api-positive-link px-0" to="/docs/api/create-a-signing-url">Create
                  URLs</v-btn> that open the signing page.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>state</td>
            <td>A unique string value to prevent CSRF. This value is optional but highly recommended.</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p class="mt-4">If the user approves your request, the user will be redirected to {{redirect_uri}}, and the
        authorisation code will be part of the redirect URI:</p>
      <v-card class="mb-4">
        <div class="card-body">
<pre><code>{{ redirectURL }}
</code></pre>
        </div>
      </v-card>
      <p>If the user choses to cancel, or the request fails, the user will be redirected to {{redirect_uri}}
        with an error:</p>
      <v-card class="mb-4">
        <div class="card-body">
<pre><code>{{ redirectWithError }}
</code></pre>
        </div>
      </v-card>
      <p>The parameters in the query:</p>
      <v-simple-table light>
        <template v-slot:default>
          <tbody>
          <tr>
            <td>error</td>
            <td>One of the following errors described in
              <v-btn text class="api-positive-link px-0" to="/docs/api/error-responses">Error responses</v-btn>.
            </td>
          </tr>
          <tr>
            <td>state</td>
            <td>A unique string value to prevent CSRF. This value is optional but highly
              recommended.
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
    export default {
        name : "RequestAnAuthorisationCode",
        data() {
            return {
                authorisationURL : 'https://signhero.io/oauth2/authorize?client_id={{client_id}}&redirect_uri={{redirect_uri}}&response_type=code&scope={{scopes}}&state={{state}}',
                redirectURL : 'https://example.io/oauth2/callback?&code={{code}}&scope=basic+profile&state={{state}}',
                redirectWithError : 'https://example.io/oauth2/callback?&error={{error}}&state={{state}}',
            }
        }
    }
</script>

<style lang="sass">
  @import "../../../styles/style"

  .RequestAuthorisationCode span.v-btn__content
    font-size: 14px !important
</style>
