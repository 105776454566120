<template>
  <v-app>
    <v-app-bar dark color="grey darken-4" app v-if="$route.name !== 'api-root'">
      <v-toolbar-title>
        <v-btn to="/landing-pane" height="50" :ripple="false">
          <img src="/signherologoW.svg" class="hidden-xs-only" height="50"/>
          <img src="/SHicon.svg" class="hidden-sm-and-up" height="50"/>
        </v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text class="shio-language-button" :ripple="false" @click="showLanguageSetting">
            <v-icon>
              mdi-earth
            </v-icon>
            <span class="hidden-xs-only">&nbsp;{{ $t( 'message.ThisLocale' ) }}&nbsp;</span>
          </v-btn>
        </template>
      </v-menu>
    </v-app-bar>
    <v-main app v-if="$route.name !== 'api-root'">
      <v-container fluid class="pa-0 fill-height">
        <router-view v-if="!choose_language_setting"></router-view>
        <v-row v-if="choose_language_setting" class="fill-height" no-gutters>
          <v-col>
            <div class="ma-auto mt-1 maxWidth-1100 pa-6">
              <h1 class="shio-section-title">{{ $t( 'message.Locales' ) }}</h1>
              <v-radio-group v-model="locale" class="mx-3 mt-6">
                <v-radio v-for="locale in locales" :key="locale" :label="$t( 'message.locale!' + locale )" :value="locale"></v-radio>
              </v-radio-group>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <div class="text-left mt-2 mx-3">
                    <v-btn class="primary-btn choose-language-btn" @click="setLocale( locale )">{{ $t( 'signflow.Ok' ) }}</v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <router-view v-if="$route.name === 'api-root'"></router-view>
  </v-app>
</template>

<script>
    import LandingPane from "../common/LandingPane";
    import util from "../../util/util";

    /**
     * Lookup the available locales from i18n.
     */
    const locales = [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ];
    export default {
        name : "the-homepage",
        components : {
            "landing-pane" : LandingPane
        },
        data()
        {
            return {
                locale : util.getLocale(),
                locales : locales,
                choose_language_setting : false
            }
        },
        computed : {
            curLocale : util.getLocale
        },
        methods : {
            /**
             * Change locale to newLoc. This involves a page reload.
             *
             * @param newLoc
             */
            setLocale( newLoc )
            {
                util.setLocale( newLoc );
            },
            showLanguageSetting()
            {
              this.locale = util.getLocale();
              this.choose_language_setting = !this.choose_language_setting;
            }
        }
    }
</script>

<style lang="sass">
  @import "../../styles/style"

  .dark
    color: white
    background-color: $background-black

  .light
    background-color: white

  .elem-pink
    color: $vivid-pink

  .v-btn.v-btn--is-elevated.v-btn--has-bg.v-btn--router.theme--dark.v-size--default:before
    background-color: $background-black !important
  </style>
