export default {
    "pdfsign": {
        "Error!Unexpected": "Pojawił się nieoczekiwany błąd. Prosimy spróbować ponownie później.",
        "SelectSigner" : "Wybierz osobę podpisującą",
        "SignatureTimestampFormat": "Podpisano dd.mm.rrrr",
        "DeclinationPlaceHolder": "Powód odmowy (opcjonalnie)",
        "SignerGreetings": "Witaj ",
        "WhoSigns": "Kto podpisuje:",
        "Sign": "Podpisz",
        "WhatSigned": "Co podpisuje: <span class='sh-nowOrNeverText'>(Kliknij, aby sprawdzić document – teraz lub nigdy)</span>",
        "SignConfirmationMsg": "Sprawdziłem/am dokumenty i mam prawo do ich podpisania.",
        "UsagePolicyInfoMsg": "Użytkowanie SignHero oznacza akceptację <a href='terms-of-use.html' target='_blank'>Warunków użytkowania</a> oraz <a href='privacy-policy.html' target='_blank'>Polityki prywatności</a>.",
        "Decline": "Odmów",
        "Cancel": "Anuluj",
        "ThankUsingService": "W każdym razie dzięki!",
        "FindMoreInformation": "Wybierz łatwy w <a class='elem-pink' href='https://www.signhero.io'>SignHero.io</a>",
        "SignEventCancelled": "Proces podpisywania anulowany",
        "CompleteProcessInfo": "Nie możesz podpisać dokumentów ponieważ to zaproszenie do podpisu zostało zamknięte. Być może podpisał(a)eś już te dokumenty lub zostało anulowane przez osobę, która wysłała Ci zaproszenie do podpisu",
        "DeclineToSignTitle": "Odmów podpisu",
        "SignedAndSent": "Dokumenty zostały podpisane.",
        "SorryUnexpectedError": "Niestety, coś poszło nie tak",
        "SessionNeedRefreshError": "Odśwież twoją sesję. Spróbuj ponownie.",
        "ReasonExpiredSession": "Kliknięty link mógł zostać zerwany lub zaproszenie zostało usunięte.",
        "InvalidLoginUrl": "Strony nie znaleziono. Sprawdź adres URL logowania i spróbuj ponownie.<br><br>Jeśli nadal otrzymujesz tę wiadomość, strona może być nie aktualna lub została usunięta.",
        "You": "ty"
    },
    "signedin": {
        "locale!fi": "Suomi",
        "locale!en": "English",
        "locale!pl": "Polski",
        "locale!de" : "Deutsch",
        "locale!sv" : "Svenska",
        "locale!es" : "Español",
        "locale!fr" : "Français",
        "locale!nl" : "Nederlands",
        "locale!th" : "ภาษาไทย",
        "Applications": "Aplikacje",
        "SearchUsers": "Szukaj użytkowników",
        "DateTimeFormat": "dd.MM.yyyy, HH:mm:ss",
        "Create": "Utwórz aplikację ",
        "TheService": "Administracja",
        "UserDeleted": "Użytkownik został nieaktywny oraz zostanie całkowicie usunięty po 30 dniach.",
        "UserRestored" : "Konto zostało przywrócone",
        "is": "jest",
        "Save": "Zapisz",
        "MyOrganization": "Moja organizacja",
        "OrganizationName": "Nazwa organizacji",
        "ManageAccounts": "Zarządzanie kontami",
        "DownloadOrganizationDataMessage": "Pobierz dane użytkownika i procesu podpisywania jako plik JSON.",
        "DownloadOrganizationDataLink": "Pobierz dane organizacji",
        "Subscription": "Subskrypcja",
        "InviteUserHeader": "Zaproś nowego użytkownika do swojej organizacji",
        "AccountStatus" : "Status konta:",
        "OwnAccount" : "Twoje konto",
        "StatusActive" : "Aktywne, użytkownik zaakceptował zaproszenie.",
        "StatusInactive" : "Nieaktywne, użytkownik jeszcze nie zaakceptował zaproszenia.",
        "StatusDeleted" : "Oznaczona do usunięcia. Subskrypcja jest nieaktywna i nie zostanie przedłużona dla tego użytkownika.",
        "AccountRights" : "Uprawnienia:",
        "DownloadOrganizationData": "Pobierz JSON",
        "DeleteOrganizationMessage": "Usuń tę organizację, swoje konto oraz inne konta administratorów i użytkowników",
        "DeleteEverything": "Usuń wszystko",
        "ConfirmDeleteEverything": "Czy na pewno usunąć konto organizacji (${groupTitle})?\n",
        "ConfirmCancelOngoingProcesses" : "W Twojej organizacji trwa ${processNum} procesów podpisywania. Należy je anulować również przed przystąpieniem do usuwania konta organizacji.\n "
                                          + "\nCzy na pewno chcesz anulować wszystkie trwające procesy i usunąć konto organizacji (${groupTitle})?\n",
        "MergeOrganizationMessage": "Organizacja może poprosić o połączenie z inną organizacją.",
        "RequestMergeDetails": "Administrator organizacji może poprosić o połączenie organizacji z inną. Prośba będzie ważna przez 24 godziny.\n\n"
            + "Po akceptacji wniosku procesy podpisywania, w tym dokumenty i użytkownicy, zostaną przeniesione do nowej organizacji, a ta organizacja zniknie. "
            + "Po połączeniu użytkownicy tej organizacji nie będą mieli żadnych uprawnień administratora. Uprawnienia musi dodać administrator nowej organizacji. "
            + "Jeśli była aktywna subskrypcja, pozostały okres subskrypcji zostanie anulowany, a połączeni użytkownicy zostaną wliczeni do subskrypcji nowej organizacji.",
        "MergeOrganizationDetails": "Konto organizacji ${groupName} poprosiło o połączenie organizacji "
            + "na konto organizacji. Prośba jest ważna przez 24 godziny.\n\n"
            + "Po akceptacji wniosku procesy podpisywania wraz z dokumentami i użytkownikami zostaną przeniesione do Twojej organizacji, "
            + "a migrowana organizacja zniknie. Użytkownicy zmigrowanej organizacji nie będą mieli żadnych praw administratora po połączeniu. "
            + "Prawa musi dodać administrator Twojej organizacji. Jeśli była aktywna subskrypcja, "
            + "migrowani użytkownicy będą wliczani do subskrypcji Twojej organizacji.",
        "RequestMerge": "Poproś o połączenie",
        "ConfirmMergeOrganisation": "Czy na pewno chcesz scalić to konto organizacji? Ponowne dzielenie kont nie jest obsługiwane.",
        "EnterAdminEmail": "Wprowadź adres e-mail administratora innej organizacji:",
        "EnterPassword": "Aby kontynuować, wprowadź swoje hasło:",
        "InvalidPassword" : "Twoje hasło się nie zgadza. Proszę spróbuj ponownie.",
        "MergeRequestSent": "Wysłano prośbę o scalenie organizacji",
        "MergeRequestCanceled": "Prośba o scalenie organizacji została anulowana",
        "MergeRequestRejected": "Odrzucono prośbę o połączenie organizacji",
        "ReceivedMergeRequests": "Otrzymane prośby o scalenie",
        "SentMergeRequest": "Wysłano prośbę o scalenie",
        "Recipient": "Odbiorca",
        "Reject": "Odrzucić",
        "Merge": "Łączyć",
        "OrganisationMerged": "Rozpoczęto połączenie organizacji",
        "UserNotDifferentOrganizationAdmin": "Użytkownik nie jest administratorem w innej organizacji",
        "UserNotFound": "Przepraszamy, nie znaleziono użytkownika!",
        "OrganizationHasActiveMergeRequest": "Organizacja ma już aktywne żądanie scalenia",
        "InvalidMergeRequest": "Nie znaleziono żądania połączenia lub wygasło",
        "Locales": "Język",
        "Sessions": "Sesje",
        "SessionType!password": "Hasło",
        "SessionType!auth_code": "Aplikacja",
        "Devices": "Urządzenia",
        "LogOutAllDevicesMessage": "Jeśli chcesz, możesz wylogować się ze wszystkich sesji na wszystkich urządzeniach.",
        "Domain" : "Domena",
        "AccessGiven" : "Data udzielenia dostępu",
        "LastAccess" : "Ostatni dostęp",
        "RemoveAccess": "Usuń dostęp",
        "NoApplicationsMessage": "Nie udzielono dostępu żadnej aplikacji.",
        "isAdmin": "Admin",
        "SendReminder": "Przypomnij o aktywacji konta",
        "EmailReminderSuccess": "Użytkownik otrzyma przypomnienie o aktywacji konta.",
        "DeleteUser": "Usuń konto",
        "RestoreAccount": "Przywróć konto",
        "InviteUser" : "Zapraszam",
        "UserInvited" : "Zaproszony użytkownik",
        "Error!Unexpected": "Pojawił się nieoczekiwany błąd. Prosimy spróbować ponownie później.",
        "Error!InvitedUserExists": "Użytkownik już dołączył do tej organizacji lub należy do innej organizacji. W tym drugim przypadku prosimy o kontakt ${email}",
        "Sign": "Podpisz",
        "Send": "Wyślij",
        "Start": "Rozpocznij",
        "Ongoing": "W toku",
        "Complete": "Archiwa",
        "UserName": "Imię i nazwisko",
        "Email": "Adres e-mail",
        "SignOut": "Wyloguj ze wszystkich sesji",
        "Logout": "Wyloguj",
        "MyAccount": "Konto",
        "Confirm": "Potwierdź",
        "Reset": "Anuluj",
        "Profile": "Mój profil",
        "Organization": "Organizacja",
        "MyIntegrations": "Integracje",
        "Statistics" : "Statystyka",
        "Device": "Urządzenie",
        "Update" : "Zapisz",
        "Delete" : "Usuń",
        "AcceptApiTerms" : "Decydując się na korzystanie z API SignHero, akceptujesz <a href='/terms/api' target='_blank'>warunki jego użytkowania</a>.",
        "CannotDeleteActiveFlows" : "Twoja organizacja ma rozpoczęty proces podpisywania. Należy najpierw go anulować przed usunięciem konta organizacji.",
        "OrganizationHasBeenDeleted" : "Administrator właśnie usunął twoją organizację. Przykro nam, że odchodzisz i mamy nadzieję, że wrócisz.",
        "MySubscription" : "Moja subskrypcja",
        "FreeTrialLeft" : "Pozostały czas bezpłatnego okresu próbnego: ",
        "Days" : "Dni.",
        "SubscriptionPeriod" : "Aktualny okres subskrypcji:",
        "NoSubscription" : "Subskrypcja nieaktywna.",
        "PleaseActivateSubscription" : "Proszę aktywować swoją subskrypcję.",
        "CurrentBill" : "Bieżące rozliczenie miesięczne:",
        "Tab1" : "Informacje o płatności",
        "Tab2" : "Historia rozliczeń",
        "Tab3" : "Info",
        "NoPaymentNotification" : "Uwaga: nie masz teraz prawidłowej metody płatności.",
        "AddCreditCard" : "Dodaj kartę kredytową do comiesięcznych rozliczeń SignHero",
        "CreditCardEmail" : "Email",
        "BillingDate" : "Termin rozliczenia",
        "Amount" : "Kwota",
        "UsersAmount" : "Użytkownicy",
        "Duration" : "Czas trwania",
        "ReferNumber" : "Numer referencyjny",
        "PDF" : "PDF",
        "Invoice" : "Faktura",
        "Action" : "Akcja",
        "LoadMore" : "Załaduj więcej",
        "SalesChannel" : "Kanał sprzedaży",
        "ResellerName" : "Sprzedawca: ",
        "ResellerContact" : "Kontakt: ",
        "ResellerMaxUsers" : "Maks. liczba użytkowników: ",
        "ResellerCurrentInvitedUsers" : "Aktualnie zaproszeni użytkownicy: ",
        "MaxResellerUsersWarning" : "Twoja liczba użytkowników przekroczyła maksymalny limit sprzedawców (${resellerMaxUsers}).",
        "Status" : "Status",
        "StartOfSubscription" : "Data rozpoczęcia subskrypcji:",
        "EstimatedBilling" : "Następna faktura ${nextBillingDate} na ${nextBillingValue}€",
        "SubInactive1" : "Subskrypcja jest nieaktywna",
        "SubInactive2" : ", nie zostanie odnowiona dla tego usuniętego użytkownika",
        "isSubscriptionAdmin" : "Admin subskrypcji",
        "CardInfo" : "Informacje o karcie",
        "CardNumber" : "Numer karty",
        "NameOnCard" : "Imię na karcie",
        "SelectCountry" : "Państwo bądź region",
        "SelectLocale" : "Wybierz język",
        "TaxInfoTitle" : "Informacje podatkowe",
        "TaxIdExplanation" : "Wybierz region podatkowy i wprowadź swój identyfikator podatkowy dla podatku VAT.",
        "SelectIdType" : "Wybierz typ identyfikatora podatkowego",
        "TaxId" : "Identyfikator podatkowy",
        "AddCardTooltip1" : "Najpierw dodaj prawidłowe informacje rozliczeniowe.",
        "AddCardTooltip2" : "Podaj prawidłowe informacje o karcie kredytowej. Wszystkie pola są obowiązkowe.",
        "AddCardTooltip3" : "Dane karty kredytowej są prawidłowe.",
        "BillingInformation" : "Informacje rozliczeniowe",
        "Edit" : "Edytować",
        "EditBillingInformation" : "Edytuj informacje rozliczeniowe.",
        "AddBillingTooltip" : "Wypełnij wszystkie obowiązkowe pola i sprawdź, czy identyfikator podatkowy jest zgodny z informacjami dotyczącymi kraju.",
        "Street" : "Adres ulicy",
        "Street2" : "Adres ulicy2",
        "City" : "Miejsce",
        "State" : "Województwo",
        "Zip" : "Kod pocztowy",
        "SaveBillingInfo" : "Zapisz informacje rozliczeniowe",
        "SaveCard" : "Zapisz informacje o karcie",
        "Expires" : "Ważny do",
        "DeleteCardOnly" : "Usuń kartę kredytową",
        "DeletePaymentInfo" : "Usuń informacje rozliczeniowe i informacje o karcie kredytowej.",
        "RemoveWholePaymentMethod" : "Usuń informacje o płatności",
        "AustralianVAT" : "Australijskie numery biznesowe",
        "EuVAT" : "Numery VAT UE",
        "UkVAT" : "Numery VAT w Wielkiej Brytanii",
        "OtherBusinessId" : "Inny identyfikator firmy",
        "PaymentInfo" : "O płatności",
        "PayOnline" : "Potwierdź płatność",
        "PaymentStatusPaid" : "Zapłacony",
        "PaymentStatusPending" : "Nierozstrzygnięty",
        "PaymentExplanation1" : "SignHero kosztuje 5€ miesięcznie za aktywnego użytkownika (cena nie zawiera podatku VAT). Do ceny zostanie doliczony podatek VAT. Nieaktywni użytkownicy nie wliczają się do Twojego rachunku. Jeśli dodasz lub usuniesz użytkowników w trakcie cyklu rozliczeniowego, automatycznie obliczymy o ile mniej lub więcej będzie wynosił następny rachunek, w oparciu o to, ile czasu do końca cyklu rozliczeniowego pozostało po wprowadzeniu zmiany. Gdy się zarejestrujesz, dajemy Ci 14-dniowy bezpłatny okres próbny, po którym musisz zarejestrować kartę kredytową do comiesięcznych płatności cyklicznych, jeśli chcesz nadal korzystać z usługi. Twoja karta kredytowa będzie automatycznie obciążana co miesiąc, zgodnie z tym, ilu użytkowników zarejestrowałeś w serwisie. Otrzymasz potwierdzenie miesięcznej płatności na adres e-mail podany podczas rejestracji karty kredytowej.",
        "PaymentExplanation2" : "Musisz podać numer VAT swojej firmy, ponieważ SignHero będzie pobierać podatek VAT od klientów zgodnie z fińskimi i unijnymi przepisami podatkowymi. W przypadku firm z obszaru UE należy podać ważny numer NIP. Jeśli Twojego kraju nie ma na liście wyboru kraju lub regionu, możesz skontaktować się z nami w celu uzyskania alternatywnych metod płatności. Daj nam przynajmniej znać, jeśli nie znalazłeś swojego kraju i z tego powodu zrezygnowałeś z korzystania z usługi. Możemy go dodać w przyszłości i damy Ci znać!",
        "PaymentExplanation3" : "Jeśli nie masz możliwości dodania karty kredytowej do automatycznego rozliczania, skontaktuj się z SignHero pod adresem <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>.",
        "PaymentExplanation4" : "Masz problem z rachunkiem? Masz pytania, jak to wszystko działa? W przypadku jakichkolwiek pytań prosimy o kontakt pod adresem <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>.",
        "CountersPending" : "Statystyki są obliczane. Spróbuj ponownie za kilka minut.",
        "WholeOrganisation" : "Cała organizacja",
        "OrganisationHas" : "Twoja organizacja ma obecnie",
        "ActiveUsers" : "aktywnych użytkowników i",
        "InactiveUsers" : "nieaktywnych użytkowników.",
        "ChartDescriptionOrg" : "Poniższy wykres przedstawia utworzone procesy podpisu i dokumenty całej organizacji.",
        "ChartDescriptionUser" : "Poniższy wykres przedstawia utworzone procesy podpisu wybranych użytkowników.",
        "SelectUsers" : "Wybierz użytkowników",
        "From" : "Od",
        "To" : "Do",
        "AmountOfDocuments" : "W wybranym okresie ilość nowych dokumentów to",
        "AmountOfSignatureProcesses" : "a ilość nowych procesów podpisu to",
        "AmountOfUsers" : "a ilość nowych użytkowników to",
        "Processes" : "Procesy",
        "Documents" : "Dokumenty",
        "Users" : "Użytkownicy",
        "UserActivity" : "Aktywność użytkownika",
        "Signatures": "Podpisy",
        "User" : "Użytkownik",
        "Inactive" : "Nieaktywny",
        "SignatureProcesses" : "Procesy podpisu w okresie",
        "AllSignatureProcesses" : "Wszystkie procesy podpisu",
        "FreeTrialAlert1" : "Twoja organizacja ma jeszcze",
        "FreeTrialAlert2" : "dni bezpłatnego okresu próbnego!",
        "ContactAdmin" : "Brak aktywnej subskrypcji, proszę skontaktuj się z administratorem!",
        "PaymentOverdueAlert" : "Zaległa płatność, proszę dodać ważną kartę",
        "PaymentOverdueAlertIgnore" : "Jeśli dodałeś już ważną kartę, zignoruj tę wiadomość",
        "PleaseAddValidCPayment" : "Brak aktywnej subskrypcji, dodaj prawidłową metodę płatności!",
        "PleaseConfirmPayment" : "Wymagane uwierzytelnienie karty. Sprawdź „Historia rozliczeń”!",
        "PleaseConfirmPaymentMainPage" : "Brak ważnej metody płatności lub wymagane uwierzytelnienie karty. Sprawdź \"Moja subskrypcja\"!",
        "ConfirmDeletePaymentMethod": "Czy na pewno chcesz usunąć informacje rozliczeniowe i informacje o karcie??",
        "ConsentDialogTitle": "Zasubskrybuj SignHero dla powtarzających się płatności?",
        "ConsentText1": "SignHero to szybkie i elektroniczne podpisy dla leniwych. Wysyłaj i podpisuj pliki PDF, śledź procesy, przypominaj sygnatariuszom, uzyskuj dostęp do ścieżki audytu, pracuj w zespołach i archiwizuj podpisane dokumenty.",
        "ConsentText2": "SignHero kosztuje 5 € miesięcznie za aktywnego użytkownika + VAT. Nieaktywni użytkownicy nie wliczają się do Twojego rachunku. Jeśli dodasz lub usuniesz użytkowników podczas cyklu rozliczeniowego, automatycznie obliczymy, o ile mniej lub więcej powinien opierać się na tym, ile z cyklu rozliczeniowego pozostało po wprowadzeniu zmiany. Po zarejestrowaniu otrzymasz 14-dniowy bezpłatny okres próbny, po którym musisz zarejestrować kartę kredytową do comiesięcznych płatności cyklicznych, jeśli chcesz nadal korzystać usługi. Twoja karta kredytowa zostanie automatycznie obciążona tego samego dnia każdego miesiąca, zgodnie z liczbą użytkowników zarejestrowanych w usłudze. Po zarejestrowaniu karty kredytowej Twoja subskrypcja będzie ważna po pomyślnym opłaceniu i pozostanie ważna, chyba że płatność się nie powiodła. Poza tym otrzymasz te e-maile:",
        "ConsentText3": "Musisz wprowadzić numer VAT swojej firmy, ponieważ SignHero będzie pobierać podatek VAT od klientów zgodnie z fińskimi i unijnymi przepisami podatkowymi. W przypadku firm w UE należy podać ważny numer VAT. Jeśli Twój kraj nie jest wymieniony w wybór kraju lub regionu, możesz skontaktować się z nami w celu uzyskania alternatywnych metod płatności. Daj nam przynajmniej znać, jeśli nie znalazłeś swojego kraju i zdecydowałeś nie korzystać z usługi z tego powodu. Możemy dodać go w przyszłości i możemy dać Ci znać!",
        "ConsentText4": "Aby anulować subskrypcję, możesz usunąć swoją kartę kredytową, a bieżąca subskrypcja zostanie anulowana na koniec okresu subskrypcji. Możesz to również zrobić, naciskając przycisk „Anuluj subskrypcję”.",
        "ConsentEmailBullet1": "wiadomość e-mail z przypomnieniem o zakończeniu okresu próbnego przed końcem okresu próbnego",
        "ConsentEmailBullet2": "pokwitowanie Twojej miesięcznej płatności na podany przez Ciebie adres e-mail",
        "ConsentEmailBullet3": "powiadomienie e-mail na 7 dni przed każdą zarejestrowaną transakcją",
        "ConsentCheckBoxLabel1": "Przeczytałem powyższe informacje",
        "ConsentCheckBoxLabel2": "Wyrażam zgodę na subskrypcję usługi subskrypcji SignHero dla płatności cyklicznych",
        "Agree": "Zgodzić się",
        "CurrentPlan" : "Aktualna subskrypcja",
        "SignHeroSubscription" : "SignHero subskrypcja",
        "CancelPlan" : "Anuluj subskrypcje",
        "CreatePlan" : "Utwórz subskrypcję",
        "PriceInfo" : "5€ miesięcznie za aktywnego użytkownika",
        "PleaseAddValidCard" : "Proszę dodać prawidłową metodę płatności",
        "CancelPlanConfirm" : "Czy na pewno chcesz anulować aktualną subskrypcję?\nStracisz pozostały okres próbny lub pozostały czas płatnej subskrypcji. Pozostały czas nie jest rekompensowany.",
        "CreatePlanConfirm" : "Czy na pewno chcesz utworzyć nową subskrypcję?",
        "numbersDidNotMatch": "Kody nie pasują. Spróbuj ponownie.",
        "toProceedEnterCode": "Aby kontynuować, wprowadź kod: ",
        "subStatus!trialing" : "Koniec okresu próbnego ",
        "subStatus!active" : "Aktywny",
        "subStatus!canceled" : "Anulowane",
        "subStatus!incomplete" : "Niekompletny",
        "subStatus!incomplete_expired" : "Wygasł",
        "subStatus!past_due" : "Zaległy",
        "ConfirmCreateSubNow" : "Nową subskrypcję możesz utworzyć teraz lub później na stronie ‘Moja subskrypcja’",
        "CreateSubscriptionNow" : "Utwórz teraz",
        "CreateSubscriptionLater" : "Później",
        "Country!Australia" : "Australia",
        "Country!Austria" : "Austria",
        "Country!Belgium" : "Belgia",
        "Country!Brazil" : "Brazylia",
        "Country!Bulgaria" : "Bułgaria",
        "Country!Canada" : "Kanada",
        "Country!Chile" : "Chile",
        "Country!Croatia" : "Chorwacja",
        "Country!Cyprus" : "Cypr",
        "Country!CzechRepublic" : "Czechy",
        "Country!Denmark" : "Dania",
        "Country!Estonia" : "Estonia",
        "Country!Finland" : "Finlandia",
        "Country!France" : "Francja",
        "Country!Germany" : "Niemcy",
        "Country!Greece" : "Grecja",
        "Country!HongKong" : "Hongkong",
        "Country!Hungary" : "Węgry",
        "Country!India" : "Indie",
        "Country!Indonesia" : "Indonezja",
        "Country!Ireland" : "Irlandia",
        "Country!Israel" : "Izrael",
        "Country!Italy" : "Włochy",
        "Country!Japan" : "Japonia",
        "Country!Korea" : "Korea",
        "Country!Latvia" : "Łotwa",
        "Country!Liechtenstein" : "Liechtenstein",
        "Country!Lithuania" : "Litwa",
        "Country!Luxembourg" : "Luksemburg",
        "Country!Malaysia" : "Malezja",
        "Country!Malta" : "Malta",
        "Country!Mexico" : "Meksyk",
        "Country!Netherlands" : "Holandia",
        "Country!NewZealand" : "Nowa Zelandia",
        "Country!Norway" : "Norwegia",
        "Country!Poland" : "Polska",
        "Country!Portugal" : "Portugalia",
        "Country!Romania" : "Rumunia",
        "Country!RussianFederation" : "Federacja Rosyjska",
        "Country!SaudiArabia" : "Arabia Saudyjska",
        "Country!Singapore" : "Singapur",
        "Country!Slovakia" : "Słowacja",
        "Country!Slovenia" : "Słowenia",
        "Country!SouthAfrica" : "Afryka Południowa",
        "Country!Spain" : "Hiszpania",
        "Country!Sweden" : "Szwecja",
        "Country!Switzerland" : "Szwajcaria",
        "Country!Taiwan" : "Tajwan",
        "Country!Thailand" : "Tajlandia",
        "Country!UnitedArabEmirates" : "Zjednoczone Emiraty Arabskie",
        "Country!UnitedKingdom" : "Wielka Brytania",
        "Country!UnitedStates" : "Stany Zjednoczone",
        "TaxType!au_abn" : "Australijski Numer Biznesowy (AU ABN)",
        "TaxType!au_arn" : "Numer referencyjny australijskiego urzędu skarbowego",
        "TaxType!eu_vat" : "Europejski numer VAT",
        "TaxType!br_cnpj" : "Brazylijski numer CNPJ",
        "TaxType!br_cpf" : "Brazylijski numer CPF",
        "TaxType!ca_bn" : "Kanadyjska BN",
        "TaxType!ca_gst_hst" : "Kanadyjski numer GST/HST",
        "TaxType!ca_pst_bc" : "Kanadyjski numer PST (Kolumbia Brytyjska)",
        "TaxType!ca_pst_mb" : "Kanadyjski numer PST (Manitoba)",
        "TaxType!ca_pst_sk" : "Kanadyjski numer PST (Saskatchewan)",
        "TaxType!ca_qst" : "Kanadyjski numer QST (Québec)",
        "TaxType!cl_tin" : "Chilijski NIP",
        "TaxType!hk_br" : "Numer Hongkongu BR",
        "TaxType!in_gst" : "Indyjski numer GST",
        "TaxType!id_npwp" : "Indonezyjski numer NPWP",
        "TaxType!il_vat" : "Izraelski VAT",
        "TaxType!jp_cn" : "Japoński numer firmy",
        "TaxType!jp_rn" : "Numer rejestracyjny japońskich zarejestrowanych firm zagranicznych",
        "TaxType!kr_brn" : "Koreański BRN",
        "TaxType!li_uid" : "Numer UID Liechtensteinu",
        "TaxType!my_frp" : "Malezyjski numer FRP",
        "TaxType!my_itn" : "Malezyjski ITN",
        "TaxType!my_sst" : "Malezyjski SST",
        "TaxType!mx_rfc" : "Meksykański numer RFC",
        "TaxType!nz_gst" : "Numer GST w Nowej Zelandii",
        "TaxType!no_vat" : "Norweski numer VAT",
        "TaxType!ru_inn" : "Rosyjski INN",
        "TaxType!ru_kpp" : "Rosyjski KPP",
        "TaxType!sa_vat" : "VAT Arabii Saudyjskiej",
        "TaxType!sg_gst" : "Singapurski podatek GST",
        "TaxType!sg_uen" : "Singapurski UEN",
        "TaxType!za_vat" : "Numer VAT w Republice Południowej Afryki",
        "TaxType!es_cif" : "Hiszpański numer CIF",
        "TaxType!ch_vat" : "Numer VAT w Szwajcarii",
        "TaxType!tw_vat" : "Tajwański podatek VAT",
        "TaxType!th_vat" : "Tajski VAT",
        "TaxType!ae_trn" : "Zjednoczone Emiraty Arabskie TRN",
        "TaxType!gb_vat" : "Numer VAT w Wielkiej Brytanii",
        "TaxType!us_ein" : "EIN Stanów Zjednoczonych",
        "BusinessAccount" : "Konto biznesowe",
        "OnlyBusinessAccountsSupported" : "W wybranym kraju obsługiwane są tylko konta firmowe.",
        "TaxTooltip" : "Należy pamiętać, że cena nie zawiera podatku VAT.",
    },
    "signflow": {
        "ButtonBack": "Wstecz",
        "ButtonGoBack": "Wstecz",
        "ButtonNext": "Dalej",
        "SearchSignFlows": "Szukaj według podpisującego, tytułu lub dokumentu",
        "AllFlows": "Wszystkie",
        "PendingMeFlows": "Czeka na mój podpis",
        "PendingOthersFlows": "Czeka na podpis innych",
        "CanceledFlows": "Anulowane",
        "CompletedFlows": "Zakończone",
        "ShowGroupProcesses": "Wszystkie procesy organizacji",
        "CancellationReasons": "Powód: ${cancellationReasons}",
        "SignedDocuments": "Dokument(y):",
        "CreatedByTitle": "Stworzone przez:",
        "CanceledByTitle": "Anulowane przez:",
        "PendingSignaturesTitle": "Czeka na podpisy:",
        "PendingMeAlert": "Czeka na <span class='elem-pink'>mój</span> podpis.",
        "PendingOthersAlert": "Czeka na <span class='elem-pink'>${count}</span> podpis(ów)",
        "SignerStatusTitle": "Status:",
        "ReminderHistory": "Historia przypomnień",
        "SignerStatus1": "W toku, zaproszenie otwarte po raz pierwszy w dniu ",
        "SignerStatus2": "W toku, zaproszenie nigdy nie było otwarte.",
        "SignerStatus3": "Podpisano ",
        "SignerStatus4": "W toku dla twórcy.",
        "SendReminders": "Przypomnij, wysyłając wiadomość email",
        "NoProcessesFound": "Nie znaleziono żadnych procesów",
        "LastEmailReminder": "Ostatnie przypomnienie: <b>${timestamp}</b>",
        "NoRemindersYet": "Nie wysłano przypomnień",
        "EmailReminderSuccess": "Wysłano przypomnienie pocztą elektroniczną.",
        "ReminderSentBy": "wysłane przez",
        "CancelProcessDialogTitle": "Anulowanie procesu",
        "CancellationReasonsPlaceHolder": "Powód anulowania",
        "CancelProcess": "Anuluj proces",
        "DeleteProcess": "Usuń proces",
        "RestartProcess": "Zacznij jeszcze raz",
        "ConfirmDeleteProcess": "Czy rzeczywiście chcesz usunąć proces?",
        "ResendDownloadInvitation": "Ponownie prześlij link do pobierania",
        "DownloadInvitationEmailSuccess": "E-mail został wysłany.",
        "DownloadInvitationEmailFail": "Wysłanie wiadomości nie powiodło się",
        "Me": "Ja",
        "SignFlowDocumentsHeading": "Co podpisuje?",
        "FlowSettings": "Dodatki",
        "FlowTitlePlaceholder": "Procesowi można nadać nazwę, aby później odnaleźć go łatwiej",
        "Signers": "Osoby składające podpis *",
        "AddSigner": "Dodaj osoby składające podpis",
        "AddSignerGroup": "Dodaj osoby podpisujące z pliku",
        "AddressBookChooseFile": "Wybierz plik",
        "AddressBookInfoHeader": "Jak dodać osoby podpisujące z pliku",
        "AddressBookInfo": "Wczytaj plik .txt lub .csv, gdzie każdy podpisujący znajduje się w oddzielnym wierszu, a pola są rozdzielone przecinkiem.",
        "AddressBookInfoExampleHeader": "Na przykład plik o nazwie CzłonkowieZarządu.txt o następującej treści:",
        "AddressBookInfoExampleContent": "Beata Novak, beata.novak@przyklad.pl<br>Michał Kowalski, michal.kowalski@przyklad.pl<br>Jan Mazur, jan.mazur@przyklad.pl<br>Ewa Kowalczyk, ewa.kowalczyk@przyklad.pl",
        "NotAnAddressBook": "The file did not contain recipient names and addresses.",
        "CheckFileEncoding": "Plik zawiera nieobsługiwane znaki. Upewnij się, że kodowanie pliku to UTF-8.",
        "SignersName": "Imię i nazwisko",
        "SignersEmail": "Adres e-mail",
        "EmailSubject": "Tytuł wiadomości e-mail:",
        "EmailMessage": "Wiadomość:",
        "EditInvitation": "Edytuj zaproszenie do podpisu",
        "ChooseInvitation": "Wybierz szablon zaproszenia",
        "UseDragAndDropSignature": "Umieść lokalizacje podpisów w dokumentach",
        "GoToSign": "Przekaż do podpisania",
        "StartNow": "Popisz teraz",
        "StartSigningNow": "Podpis można złożyć teraz lub później z zakładki W toku.",
        "RequestEmailSendSigners": "Wniosek o złożenie podpisu został wysłany osobom składającym podpisy pocztą elektroniczną. ",
        "InformProgressNotifications": "Informacje na temat postępu zostaną do Ciebie przesłane pocztą elektroniczną.",
        "Later": "Później",
        "Ok" : "Dobrze",
        "DefaultEmailSubject": "Ktoś zaprosił Ciebie do złożenia podpisu",
        "DefaultEmailMessage": "${$cc_user_info.name} z ${$cc_user_info.organization.display_name} (${$cc_user_info.email}) zaprasza Cię do złożenia podpisu elektronicznego z wykorzystaniem SignHero.",
        "SessionType!password": "Hasło",
        "SessionType!auth_code": "Aplikacja",
        "Signer": "Podpisujący",
        "Created": "Utworzono",
        "Canceled": "Anulowany",
        "Error!Unexpected": "Pojawił się nieoczekiwany błąd. Prosimy spróbować ponownie później.",
        "Sign": "Podpisz",
        "Send": "Wyślij",
        "Download": "Pobierz",
        "SignedBy": "Podpisane przez:",
        "Start": "Rozpocznij",
        "Complete": "Archiwa",
        "WhoIsSigning": "Kto podpisze?",
        "OnlyMe": "Tylko ja",
        "OnlyOthers": "Tylko inni",
        "MeAndOthers": "Ja i inni",
        "Email": "Adres e-mail",
        "numbersDidNotMatch": "Kody nie pasują, Spróbuj ponownie.",
        "toProceedEnterCode": "Aby kontynuować, wprowadź kod: ",
        "Confirm": "Potwierdź",
        "Continue": "Kontynuuj",
        "Cancel": "Anuluj",
        "Reset": "Anuluj",
        "selectFromDisk": "Lokalny",
        "fileTooLarge": "Maksymalny rozmiar pliku to ${maxSize}.",
        "allFilesTooLarge": "Maksymalny rozmiar wszystkich plików to ${maxSize}. Załadowałeś już ${uploadedSize}.",
        "fileNumberTooLarge": "Wzcytaj do ${maxFileNumber} plyków.",
        "uploadError": "Przepraszamy, wystąpił problem z plikiem. Może zły format lub jest już podpisany.",
        "select": "Wybierz",
        "OngoingProcess" : "Procesy w toku",
        "Error!FilesOutdated": "Błąd przekroczenia limitu czasu sesji. Prześlij pliki i uzupełnij dane ponownie, jeśli to konieczne",
        "Error!DocumentError" : "Przepraszamy, sesja wygasła lub wystąpił problem z plikiem. Może zły format. Prześlij pliki i uzupełnij dane ponownie, jeśli to konieczne.",
        "OnlyPdf": "Akceptowane typy plików to pdf",
        "OnlyCancelledProcessAllowed": "You can only restart cancelled process.",
        "CreateNewTags" : "Utwórz nowy tag",
        "CreateNewTagsHint" : "Naciśnij enter, aby dodać nowy tag: maksymalnie 20 znaków",
        "ManageTags" : "Zarządzaj tagami",
        "Tags" : "Tagi",
        "FilterByTag" : "Filtruj według tagu",
        "ErrorTagExists" : "Tag już istnieje!   ",
        "MyOrganization" : "Moja organizacja",
        "OtherOrganizations" : "Inne organizacje",
        "OwnedBy" : "Posiadany przez:",
        "SavedTemplates" : "Wybierz szablon zaproszenia",
        "CreateTemplateTitle" : "Zapisz szablon zaproszenia",
        "NameTemplate" : "Nazwij szablon zaproszenia",
        "NameTemplateHint" : "Używany tylko do wyszukiwania, nie będzie wyświetlany w zaproszeniach.",
        "TemplateCreated" : "Zapisany szablon zaproszenia.",
        "DeleteTemplateTitle" : "Usuń udostępniony szablon zaproszenia",
        "DeleteTemplateConfirm" : "Czy na pewno? To wpływa na całą organizację.",
        "Update" : "Aktualizacja",
        "Edit" : "Edytować",
        "Save": "Zapisz",
        "ExplainTemplate1" : "Organizacja nie ma zapisanych szablonów zaproszeń. Szablony zaproszeń mogą zostać zapisane po ich edycji.",
        "ExplainTemplate2" : "Możesz zapisać – ale nie musisz! ",
        "ExplainTemplate3" : "Bieżącego zaproszenia do podpisu można użyć bez zapisywania.",
        "ExplainTemplate4" : "Możesz zapisać często używane szablony zaproszeń. Utworzone szablony są widoczne dla całej organizacji i mogą być edytowane lub usuwane przez każdego w organizacji.\nBieżącego zaproszenia do podpisu można użyć bez zapisywania.",
        "UpdateTemplate" : "Szablon będzie widoczny, edytowalny i usuwalny dla wszystkich w organizacji.\n\nNazwa szablonu: ",
        "DeleteTemplate" : "Usunięto szablon.",
        "TemplateNoname" : "noname",
        "Error!TemplateNameExist": "Szablon już istnieje, czy należy go nadpisać?",
        "Error!TemplateNameEmpty": "Brak nazwy szablonu!",
        "ProcessesTabName1" : "Moje procesy",
        "ProcessesTabName2" : "Moje podpisywanie zaproszeń",
        "ShowOnly12MonthInfo" : "Wyświetlanie procesów z ostatnich 12 miesięcy",
        "MaxSigners" : "Maksymalna liczba sygnatariuszy procesu wynosi ",
        "MaxSigners1" : "Usuń ",
        "MaxSigners2" : " sygnatariuszy.",
        "MaxSigners3" : "Nie można dodać więcej sygnatariuszy."
    },
    "message": {
        "IForgotMyPassword" : "Zresetuj hasło",
        "locale!fi": "Suomi - FI",
        "locale!en": "English - EN",
        "locale!pl": "Polski - PL",
        "locale!de" : "Deutsch - DE",
        "locale!sv" : "Svenska - SV",
        "locale!es" : "Español - ES",
        "locale!fr" : "Français - FR",
        "locale!nl" : "Nederlands - NL",
        "locale!th" : "ภาษาไทย - TH",
        "SignIn": "Zaloguj",
        "Save": "Zapisz",
        "SessionType!password": "Hasło",
        "SessionType!auth_code": "Aplikacja",
        "Error!Unexpected": "Pojawił się nieoczekiwany błąd. Prosimy spróbować ponownie później.",
        "Sign": "Podpisz",
        "Welcome": "Witamy!",
        "CompleteProcessInfo": "Nie możesz podpisać dokumentów ponieważ to zaproszenie do podpisu zostało zamknięte. Być może podpisał(a)eś już te dokumenty lub zostało anulowane przez osobę, która wysłała Ci zaproszenie do podpisu",
        "InvalidLoginUrl": "Strony nie znaleziono. Sprawdź adres URL logowania i spróbuj ponownie.<br><br>Jeśli nadal otrzymujesz tę wiadomość, strona może być nie aktualna lub została usunięta.",
        "SignInHere": "Zaloguj",
        "ReasonTitle": "NAJCZĘSTSZE PRZYCZYNY",
        "Reason1": "Ten dokument został już podpisany",
        "Reason2": "Jeden z podpisujących odmówił podpisania",
        "Reason3": "Nadawca anulował zaproszenie",
        "Reason4": "Zaproszenie wygasło po roku",
        "Reason5": "Wprowadzony adres jest nieprawidłowy",
        "Reason6": "Wysłano Ci przypomnienie, stare zaproszenie zostało automatycznie anulowane",
        "SeeYouSoon" : "Do zobaczenia",
        "HowToOpenExpiredSession" : "Aby otworzyć  sesję ponownie, kliknij link w zaproszeniu.",
        "ClearCookieWarning": "Jeśli używasz publicznych lub wspólnych urządzeń, pamiętaj o usunięciu plików cookie, historii i pamięci podręcznej z przeglądarki.",
        "JustASec": "Jeszcze chwilę",
        "SorryUnexpectedError": "Strony nie znaleziono!",
        "InvitationInvalidatedReason": "Kliknięty link mógł zostać zerwany lub zaproszenie zostało usunięte.",
        "You": "ty",
        "DownloadPageTitle": "Podgląd i pobieranie podpisanych dokumentów",
        "DownloadLinkInfo" : "Link ten pozostanie aktywny przez rok od zakończenia procesu podpisania.",
        "Download": "Pobierz",
        "OpenAuditTrail": "Ścieżka audytu",
        "ProcessNotFound": "Proces podpisywania nie został znaleziony. Czy na pewno identyfikator procesu jest poprawny?",
        "SignatureEvents": "Podpisy",
        "ProcessStatus!COMPLETED": "Zakonczony",
        "ProcessStatus!CANCELED": "Odrzucony",
        "ProcessStatus!PENDING": "W oczekiwaniu",
        "ProcessStatus!UNKNOWN": "Nieznany",
        "SignatureProcessId": "Identyfikator procesu",
        "SignatureProcess": "Proces podpisu",
        "SignatureFlowCreated": "Stworzony",
        "SignatureFlowStatus": "Status",
        "NumberOfDocuments": "Liczba dokumentów",
        "SignedBy": "Podpisane przez",
        "SignTime": "Data podpisu",
        "AuthType": "Typ uwierzytelniania",
        "AuthMethod!verification_code": "Weryfikacja adresu e-mail",
        "AuthMethod!access_token": "SignHero konto",
        "AuthMethod!strong_auth": "Mocny",
        "AuthMethod!otp_token": "Strona trzecia",
        "AuthMethod!otac": "E-mail",
        "AuthMethod!signer_token": "E-mail",
        "AuthMethod!on_site": "Twarzą w twarz",
        "PrivacyPolicy": "Polityka prywatności",
        "TermsOfUse": " Warunki użytkowania",
        "ThisLocale": "PL",
        "ThirdParty": "Trzecia strona",
        "NameAndSurname": "Imię i nazwisko",
        "SignUpButtonLabel": "Utwórz konto",
        "WelcomeToSignHero": "Witamy w SignHero",
        "Complete": "Archiwa",
        "Email": "Służbowy adres e-mail",
        "Company": "Organizacja",
        "ConfirmAccount": "Kontynuuj",
        "Confirm": "Potwierdź",
        "Cancel": "Anuluj",
        "ConfirmAccountTitle": "Ustaw hasło dla swojego konta <span class='elem-pink'>SignHero</span>",
        "ResetPasswordTitle": "Zresetuj hasło dla swojego konta <span class='elem-pink'>SignHero</span>",
        "ResetPassword": "Ustaw nowe hasło",
        "Reset": "Anuluj",
        "Organization": "Organizacja",
        "WelcomeMessage": "Zaloguj się do swojego konta <span class='elem-pink'>SignHero</span>",
        "WelcomeText": "Wprowadź swoje dane poniżej.",
        "EnterYourDetailsBelow": "Wprowadź swoje dane poniżej.",
        "DontHaveSignHeroAccount": "Nie masz konta <span class='elem-pink'>SignHero</span>?",
        "GetStarted": "Zarejestruj się",
        "AlreadyHaveSignHeroAccount": "Masz już konto <span class='elem-pink'>SignHero</span>?",
        "CreateYourSignHeroAccount": "Utwórz konto <span class='elem-pink'>SignHero</span>",
        "AcceptTermsOfUseAndPrivacyPolicy": "Klikając przycisk Kontynuuj, zgadzasz się na <a class='terms-disclaimer-link' href='terms-of-use.html' target='_blank'>Warunki użytkowania</a> i <a class='terms-disclaimer-link' href='privacy-policy.html' target='_blank'>Politykę prywatności</a> SignHero.",
        "Docs": "Documentation",
        "YouFoundApi": "Udało Ci się znaleźć API SignHero!",
        "CheckApiDocumentation": "Aby rozpocząć pracę, zapoznaj się z naszą aktualną <a class=='elem-pink' href='${apiDocsLink}'>dokumentacją API</a>.",
        "AuditTrail" : "Ścieżka audytu",
        "EnterProcessId" : "Wpisz identyfikator procesu podpisu, który znajduje się w polu podpisu w każdym dokumencie:",
        "SubmitID" : "Wyślij",
        "NoProcessId" : "Wprowadź identyfikator procesu.",
        "ConfirmError1" : "Poproś o nowe zaproszenie lub link do zresetowania hasła ze strony ",
        "ConfirmError2" : ".",
        "SignHeroDotIo" : "SignHero.io",
        "ResetPWError" : "Ten link może być już nieaktualny.<br>Zresetuj hasło ze <a href='/#/signin' class='elem-pink'>strony logowania</a>.",
        "MustBeEmail" : "Wprowadź adres e-mail.",
        "MustBeValidFullName": "Wpisz prawidłową nazwę.",
        "HyperlinksNotAllowed": "Hiperłącza są niedozwolone.",
        "EnterValidCharacters": "Wprowadź tylko obsługiwane znaki.",
        "Error!UserExists" : "Użytkownik z podanym adresem e-mail lub nazwą użytkownika już istnieje. Jeśli zapomniałeś hasła, kliknij \"Zaloguj\" i zmień je za pomocą linku \"Zresetuj hasło\".",
        "UserSignedUp" : "Witamy w SignHero! Wysłaliśmy Ci wiadomość e-mail z instrukcjami, jak aktywować konto. Jeśli masz już konto, możesz zresetować hasło na stronie logowania.",
        "Error!tax_id_invalid" : "Nieprawidłowy identyfikator podatkowy.",
        "CreditCardSaved" : "Dodano kartę kredytową.",
        "BillingInformationSaved" : "Billing information saved.",
        "Error!card_declined" : "Karta kredytowa została odrzucona przez system płatności.",
        "Error!incorrect_cvc" : "Karta kredytowa została odrzucona przez system płatności.",
        "Error!expired_card" : "Karta kredytowa została odrzucona przez system płatności.",
        "Error!processing_error" : "Karta kredytowa została odrzucona przez system płatności.",
        "Contact" : "Kontakt",
        "name" : "Imię",
        "email" : "Email",
        "LoginEmail" : "Adres email",
        "EnterYourEmail" : "Wprowadź swoje email:",
        "Password" : "Hasło",
        "HelloThere" : "Witaj,",
        "ChangePassword" : "Zmień hasło",
        "CurrentPassword" : "Stare hasło",
        "NewPassword" : "Nowe hasło",
        "RepeatPassword" : "Powtórz nowe hasło",
        "PasswordSet" : "Hasło ustawione",
        "DefaultDialogTitle" : "",
        "SignHeroSentPasswordResetRequest" : "Wysłano prośbę o zresetowanie hasła, jeśli jesteś zarejestrowanym użytkownikiem. Sprawdź swoją skrzynkę.",
        "SignHeroSentConfirmTokenRequest" : "Potwierdzenie żądania utworzenia nowego konta. Sprawdź swoją skrzynkę.",
        "Action!SetPassword" : "Zapisz",
        "Action!ConfirmAccount" : "Potwierdź konto",
        "TooltipSetPassword" : "Nieprawidłowy adres e-mail lub hasła nie pasują.",
        "Action!EditProfile" : "Edytuj profil",
        "Action!SignOut" : "Wyloguj",
        "Action!SignIn" : "Zaloguj",
        "Update" : "Zapisz",
        "Error!PasswordChangeFailedInvalidPassword" : "Twoje stare hasło jest niezgodne. Proszę spróbuj ponownie.",
        "Error!InvalidEmail" : "Wprowadź swoje email.",
        "Error!InvalidFullName" : "Wpisz prawidłowe imię i nazwisko",
        "Error!InvalidPasswordReset" : "Zmiana hasła zakończona niepowodzeniem. Poproś o nowy za pomocą linku \"Zresetuj hasło\" i spróbuj ponownie.",
        "Error!InvalidCredentials" : "Logowanie nieudane. Prosimy o sprawdzenie nazwy użytkownika/adresu email oraz hasła.",
        "Error!InvalidScopes" : "Twoje prawa dostępu nie pozwalają na korzystanie z tej aplikacji. Prosimy o kontakt z administracją.",
        "Error!TemporarilyLockedOut" : "Logowanie na tym urządzeniu jest tymczasowo zablokowane z powodu powtarzających się błędów. Jeśli zapomniałeś hasła, zresetuj je w sekcji Nie \"Zresetuj hasło\" połączyć. Logowanie zostanie odblokowane za ${minutes} minut.",
        "Error!TokenCheckFailed" : "Ten link może być już nieaktualny.<br>Zresetuj hasło ze strony logowania.",
        "History": "Historia",
        "NavigationAccountButton": "Konto",
        "NavItemAccount": "Konto",
        "NavigationMenuButton": "Menu",
        "NavigationContactButton": "Kontakt",
        "NavItemIntroduction": "Wprowadzenie",
        "MainSlogan": "SignHero - łatwiej się nie da!",
        "SubSloganPart1": "To sprzeciw wobec złożoności, przekombinowanym procesom",
        "SubSloganPart2": "i starym sposobom robienia rzeczy.",
        "GoToRegister": "Wypróbuj za darmo",
        "TitleLazy": "Łatwe wybieranie",
        "TextLazyLine1": "Identyfikatory bankowe, wzory podpisów oraz odręczne podpisy są przestarzałe;",
        "TextLazyLine2": "reprezentują nieprzydatne procesy, które marnują czas i pieniądze.",
        "TextLazyLine3": "Użytkownicy SignHero chcą po prostu skupić się na tym, co ważne. Dlatego dajemy Tobie to, co najważniejsze.",
        "MetricsTitle": "Średnie dane tygodniowe:",
        "Metrics1" : "Ponad 6000+ użytkowników SignHero",
        "Metrics2" : "Ponad 2000+ podpisanych umów",
        "Metrics3" : "Ponad 100+ nowych kont",
        "TitleAccount": "Konto",
        "TextAccount": "Konto SignHero zawiera wszystko to, czego naprawdę potrzebujesz do podpisu elektronicznego (eSign):",
        "Feature1": "Wyślij & Podpisz PDF",
        "Feature2": "Śledź proces",
        "Feature3": "Przypomnij sygnatariuszowi",
        "Feature4": "Dostęp do dziennika kontroli integralności podpisu",
        "Feature5": "Pracuj w grupie",
        "Feature6": "Dokumenty archiwalne",
        "UnlimitedSigners": "Nieograniczona liczba sygnatariuszy i zaproszeń.",
        "EasyRegister": "Zarejestruj się podając swoje imię i nazwisko, adres e-mail oraz organizację.",
        "Register": "Utwórz konto",
        "TitlePricing": "Cennik",
        "MonthlyPrice": "5 euro / 1 użytkownik / miesiąc",
        "MonthlyBill": "(Rozliczanie miesięczne. Cena nie zawiera podatku VAT. Do cen zostanie doliczony podatek VAT obowiązujący w tym czasie.)",
        "Benefits1": "Nielimitowana liczba podpisów elektronicznych",
        "Benefits2": "Archiwum dla Twoich dokumentów",
        "Benefits3": "Konto dla wielu użytkowników",
        "Benefits4": "Sygnatariusze nie potrzebują konta",
        "ButtonFreeTrial": "Zacznij 14-dniowy okres próbny",
        "CreditCardInfo": "Nie wymagamy karty kredytowej",
        "TitleAPI": "API & Integracje",
        "IntegrateSignHero": "Zintegruj nasz system, gdziekolwiek potrzebujesz.",
        "ToIntroBTN": "Wprowadzenie",
        "ToDocsBTN": "Dokumentacja",
        "ToGmailBTN": "Gmail",
        "ToZapierBTN": "Zapier",
        "TitleDoubts": "Wątpliwości i pytania",
        "DoubtsLegal": "Czy podpis cyfrowy (eSigning) jest legalny?",
        "TitleShortAnswer": "Krótka odpowiedź:",
        "AnswerLegalParagraph1": "Oczywiście. Nawet od ponad dekady.",
        "AnswerLegalParagraph2": "Podpisywanie dokumentów długopisem nie jest szczególnie bezpiecznym sposobem. Prawo jest po naszej stronie; sprawdź akty prawne",
        "eIDAS": "eIDAS",
        "ESIGN": "ESIGN",
        "And": "oraz",
        "Acts": ".",
        "TitleLongAnswer": "Długa odpowiedź:",
        "LegalLongAnswerParagraph1": "Wiesz, podpisanie kartki papieru nie gwarantuje zawarcia umowy. Podpisy odręczne mogą być sfałszowane, kwestionowane, a nawet trudne do udowodnienia w sądzie. W rzeczywistości, jeśli nadal podpisujesz dokumenty długopisem, w zasadzie niczego nie masz pewnego.",
        "LegalLongAnswerParagraph2": "Poza tym w UE masz swobodę podejmowania umów, zgodnie z którą możesz wybrać sposób ich potwierdzania.                                                                                              Sprawdź, czy Twoja firma nie ma specjalnych wymagań (np. ustawy o praniu brudnych pieniędzy), a następnie użyj podpisu elektronicznego eSign.",
        "LegalLongAnswerParagraph3": "Uwierzytelnianie jest jak ubezpieczenie. Na przykład, czy pokazałeś swój dowód tożsamości podczas podpisywania obecnej umowy o pracę? Niektóre umowy są obarczone niskim ryzykiem i wymagają jedynie podstawowego ubezpieczenia; w tym przypadku wystarczy uwierzytelnienie poczty e-mail.",
        "LegalLongAnswerParagraph4": "SignHero jest obecnie wykorzystywane do: umów handlowych / umów o pracę / umów NDA / protokołów ze spotkań / umów najmu",
        "LegalLongAnswerParagraph5": "Podpisy oparte na standardach SignHero są zgodne z aktami prawnymi UETA, Esign Act i eIDAS.",
        "DoubtsPrice": "Jak to działa? Tylko 5 euro miesięcznie?",
        "PriceShortAnswerParagraph1": "Wystarczy kilka kliknięć, aby podpisać i zarchiwizować dokumenty. Zaproszenia do podpisania są wysyłane pocztą elektroniczną, a podpisanie nie wymaga konta.\n"
                                      + "Cena wynosi 5 € za użytkownika miesięcznie. Będzie ona automatycznie naliczana co miesiąc po wprowadzeniu danych karty kredytowej.\n"
                                      + "Możesz zrezygnować w dowolnym momencie, więc płacisz tylko za miesiąc.",
        "PriceLongAnswerParagraph1": "SignHero umożliwia podpisywanie dokumentów PDF za pomocą komputerów i urządzeń mobilnych oraz może być zintegrowany z dowolnym CRM, cyfrowym archiwum\n"
                                     + " lub innym system zarządzania dokumentami z naszym publicznym API. Możesz nawet zalogować się bezpośrednio na swoje konto Gmail za pomocą wtyczki SignHero bez opuszczania skrzynki odbiorczej.",
        "PriceLongAnswerParagraph2": "Możesz założyć konto bez żadnych zobowiązań i otrzymać 14-dniowy darmowy okres próbny. Podczas rejestracji nie są potrzebne żadne dane karty kredytowej.\n"
                                     + "Bezpłatny okres próbny obejmuje wszystkie funkcje, więc możesz go przetestować w całości. I nie martw się, nigdy nie spamujemy oraz nie wysyłamy żadnych biuletynów.",
        "PriceLongAnswerParagraph3": "Cena wynosi 5 € za użytkownika miesięcznie. Jako płacący użytkownik możesz wysyłać żądania podpisu, monitorować zdarzenia związane z podpisami.\n"
                                     + "Przechowuj swoje dokumenty w archiwum SignHero i pracuj zespołowo na koncie swojej organizacji.\n"
                                     + "Możesz dodać do swojego konta prawie nieskończoną liczbę członków zespołu, a każdy nowy użytkownik dodaje kolejne 5 € do miesięcznego abonamentu.",
        "TitleThisIsUs": "O nas",
        "LinkedIn": "SignHero LinkedIn",
        "CompanyName": "Avaintec",
        "CompanyAddress": "Kansakoulukuja 3, 00100 Helsinki",
        "CompanyWeb": "www.avaintec.com",
        "TitleMoreQuestion": "Masz więcej pytań?",
        "SignHeroEmail": "hello@signhero.io",
        "ApiDocsLink": "Dokumenty API",
        "TermOfUseLink": "Zasady korzystania",
        "PrivacyPolicyLink": "Polityka prywatności",
        "CopyrightYear": "© " + new Date().getFullYear() + " ",
        "CompanyOyName": "Avaintec Oy",
        "RegulationNotice": "Podpisy elektroniczne mają taki sam status prawny jak podpisy odręczne w USA i UE dzięki",
        "ESignAct": "ustawie ESIGN",
        "eIDASRegulations": "przepisom eIDAS",
        "NavItemAPIsIntegrations": "API & Integracje",
        "NavItemDoubtsWorries": "Wątpliwości i pytania",
        "NavItemThisIsUs": "O nas",
        "NavItemContactUs": "Skontaktuj się z nami",
        "NavItemChooseEasy": "Łatwe Wybieranie",
        "Error!too_many_incorrect_email": "Zbyt wiele niepoprawnych adresów e-mail, spróbuj ponownie jutro.",
        "PasswordRequirement" : "Twoje hasło powinno mieć co najmniej 10 znaków i zawierać cyfrę, wielką literę, małą literę oraz znak specjalny. Alternatywnie możesz użyć co najmniej 15 znaków. Nie używaj więcej niż dwóch kolejnych identycznych znaków.",
        "EmailRequired" : "Email jest wymagany",
        "EmailMustValid" : "Adres email musi być poprawny",
        "PasswordMustValid" : "Hasło musi spełniać wymagania dotyczące złożoności",
        "RepeatPasswordMustSame" : "Hasła muszą być identyczne",
        "TitleNews" : "Wiadomości",
        "ReadMoreLink" : "Czytaj dalej",
        "OtherNewsBtn" : "Inne wiadomości",
        "NewsListBg1" : "Tym się zajmujemy",
        "NewsListBg2" : "Przeczytaj wiadomości o naszych osiągnięciach, produktach i wzmiankach w mediach.",
        "Share" : "Udostępnij",
        "Youtube": "SignHero Youtube",
        "Locales": "Język",
    },
    "client": {
        "ClientSecretInfo": "Przechowuj sekret bezpiecznie. Możesz go zmienić, ale po zakończeniu tej sesji nie będziesz mógł go ponownie zobaczyć.",
        "Error!InvalidDomain": "Nieprawidłowa nazwa domeny.",
        "DomainValidationDownloadInfo1": "Procedura weryfikacji:",
        "DomainValidationDownloadInfo2": "Pobierz następujący plik:",
        "DomainValidationDownloadInfo3": "Prześlij ten plik do katalogu głównego domeny:",
        "DomainValidationDownloadInfo4": "Sprawdź, czy następujący link prowadzi do przesłanego pliku:",
        "DomainValidationDownloadInfo5": "Wróć na tę stronę i kliknij przycisk 'Zweryfikuj domenę'.",
        "OAuthApplications": "Aplikacje OAuth2",
        "Application": "Aplikacja OAuth2",
        "IntegrateWithOAuth": "Zintegruj z OAuth2",
        "NewOAuthApplication": "Nowa aplikacja OAuth2",
        "ApplicationName": "Nazwa aplikacji *",
        "AddOAuthApplication": "Dodaj aplikację",
        "AddUri": "Dodaj URI",
        "CreationDate": "Data utworzenia",
        "CreatedBy": "Twórca",
        "AllowedGrantTypes": "Dozwolone rodzaje uprawnień",
        "OAuthRedirectURIs": "Identyfikatory URI przekierowania *",
        "OAuthClientsDomainTitle": "Domena aplikacji *",
        "OAuthClientsDomainURI": "Domena aplikacji",
        "AppDescription": "Opis aplikacji",
        "VerifyDomain": "Zweryfikuj domenę",
        "DomainVerified": "Domena została zweryfikowana!",
        "WhyValidateDomain": "Przed użyciem aplikacji musimy potwierdzić, że jesteś właścicielem domeny. Szczegóły podano w <a class='paragraph-links' href='/docs/api' target='_blank'>dokumentacji API</a>.",
        "FailedDomainValidation": "Nie można zweryfikować domeny. Sprawdź, czy instrukcje zostały wykonane prawidłowo.",
        "ClientSecret": "Sekret klienta",
        "ClientId": "Identyfikator klienta",
        "ClientCredentials": "Poświadczenia klienta",
        "VerifiedApp": "Aktywne",
        "UnverifiedApp": "Nieaktywne",
        "ResetClientSecret": "Zmień sekret klienta",
        "LastClientSecretReset": "Ostatnia zmiana",
        "ConfirmDeleteApiClient": "Czy na pewno chcesz usunąć tę aplikację?\nAny kontynuować , wprowadź kod: ${rndCode}",
        "ConfirmResetClientSecret": "Czy na pewno chcesz zmienić sekret klienta?\nAny kontynuować , wprowadź kod: ${rndCode}",
        "AppHasBeenDeleted": "Usunięto aplikację ${appName}!",
        "AppHasBeenCreated": "Utworzono aplikację ${appName}!",
        "YourChangesHaveBeenSaved": "Zmiany zostały zapisane!",
        "CheckApiDocs": "Przed użyciem aplikacji musimy potwierdzić, że jesteś właścicielem domeny. Weryfikacja wymaga uprawnień administratora do folderu głównego domeny. Tę czynność wystarczy wykonać raz. Szczegóły podano w <a href='/docs/api' target='_blank'>dokumentacji API</a>.",
    },
    "oauth2consent": {
        "AllowAccess": "Czy zezwolić na dostęp",
        "OAuthAccessDescriptionHeader": "Aplikacja <span class='elem-pink'>${appName}</span> " +
                                         "wymaga dostępu, aby za jej pomocą można było:",
        "OAuthBasicPermissions": "  <li>wysyłać nowe i przypominające zaproszenia do podpisania;</li>" +
                                 "  <li>przeglądać, anulować lub usuwać zaproszenia wysłane przez nią;</li>" +
                                 "  <li>przeglądać podpisane dokumenty wysłane przez nią;</li>",
        "OAuthSigningUrlPermissions": " <li>przejść do podpisywania dokumentów przesłanych przez nią;</li>",
        "OAuthProfilePermissions": " <li>Uzyskaj dostęp do swojego imienia i nazwiska, adresu e-mail, nazwy firmy, nazwy" +
                                   " organizacji, wybranego języka komunikacji oraz sprawdź, czy masz uprawnienia " +
                                   "administratora</li>",
        "OAuthAllDataPermissions": " <li>Dostęp do anulowania oraz usuń wszystkie swoje zaproszenia do podpisania</li>" +
                                   " <li>Uzyskaj dostęp do wszystkich swoich dokumentów</li>",
        "OAuthAccessWarning": "Oznacza to również zgodę na warunki usługi i politykę prywatności ${appName}.",
        "QuestionToAllowAccess": "Czy zezwolić aplikacji <span class='elem-pink'>${appName}</span> " +
                                 "na dostęp do Twojego konta SignHero <span class='elem-bold'>${userEmail}</span>?" +
                                 "<br><br>Zawsze możesz wycofać zgodę w ustawieniach konta SignHero.",
        "Cancel": "Anuluj",
        "Allow": "Zezwól",
        "NotFound": "Przepraszamy. Nie ma takiej aplikacji",
        "OAuthResourceNotFound": "Niestety, ten problem musi zostać rozwiązany w aplikacji, która Cię tu przekierowała.",
    }
}
