<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section22" class="DownloadDocument sectionContainer">
      <h1 class="section-title">Download a document</h1>
      <div class="d-flex version-switch mt-4">
        <span>v3</span>
        <v-switch v-model="isV4" class="mx-2"></v-switch>
        <span>v4</span>
      </div>
      <p><b>Scope:</b> <i>basic</i></p>
      <hr class="line-break">
      <div v-if="!isV4">
        <p>To get a copy of the current version of a document make a download request to the <i>documents</i> endpoint:
        </p>
        <v-card>
          <div class="card-body">
  <pre><code>POST /documents HTTP/1.1
   Host: api.signhero.io
   Content-Type: application/json

   {
       "action_str": "retrieve",
       "data_type": "document",
       "trans_map": {
           "auth_key": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
       },
       "request_map": {
           "document_id": "d807b62c-08bb-11e7-a142-080027334cc4"
       }
   }
  </code></pre>
          </div>
        </v-card>
      </div>
      <div v-if="isV4">
        <p>To get a copy of the current version of a document, download it from the <i>documents</i> endpoint using
          document ID as a path component:</p>
        <v-card>
          <div class="card-body mb-4">
  <pre><code>curl 'https://api.signhero.io/4/documents/8e4dc18d-ec84-11ea-8ac3-76ad82d12dd4' \
    > --header 'Authorization: Bearer YYWMtGeygaKCEeahbgX148SD2wAAAVipR'
  </code></pre>
          </div>
        </v-card>
        <p>Headers:</p>
        <v-simple-table light>
          <template v-slot:default>
            <tbody>
            <tr>
              <td>Authorization</td>
              <td>Access token in format <i>{{ authorizationContent }}</i></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <p class="mt-4">The successful response is a binary stream with MIME type application/pdf.</p>
      <p>The downloaded document will always be the current version of it, which may have all, some
        or none signatures depending on how many people have signed already.</p>
    </div>
  </div>
</template>

<script>
    export default {
        name : "DownloadADocument",
        data()
        {
            return {
                isV4 : true,
                authorizationContent : 'Bearer {{access_token}}'
            }
        }
    }
</script>

<style scoped>

</style>
