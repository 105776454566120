import Vue from "vue";
import CloudcoreVueWidgets from "@cloudlace/vue-widgets";
import { cloudcoreI18n } from "@cloudlace/vue-widgets";
import { setSignoutListener } from "@cloudlace/vue-widgets";

// Set listener for sign-out global event to sign out.
setSignoutListener();

import i18n from "../i18n/i18n";

// Merge locale messages from cloudcroreI18n into the current localisation dictionary.
i18n.mergeLocaleMessage( window.$cc_ui_locale, cloudcoreI18n[ window.$cc_ui_locale] );

// Startup the plugin in the current UI locale.
Vue.use( CloudcoreVueWidgets, {
    locale : window.$cc_ui_locale
} );