<template>
  <v-container fluid class="fill-height pa-0">
    <div class="content text-left ma-auto mr-0">
      <div id="section2" class="Authentication sectionContainer">
        <h1 class="section-title">Authentication</h1>
        <hr class="line-break">
        <p>You need an authentication token every time you make a call to SignHero API. SignHero API offers two
          authorisation grants for your application to authenticate users:
          <v-btn text class="api-negative-link" to="/docs/api/client-credentials"> Client credentials</v-btn>
          and
          <v-btn text class="api-negative-link" to="/docs/api/authorisation-code">Authorisation code</v-btn>
          .
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
    export default {
        name : "Authentication",
        data()
        {
            return {
                drawer : true
            }
        }
    }
</script>

<style lang="sass">
  @import "../../../styles/style"
</style>
