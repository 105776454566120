<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section23" class="sectionContainer">
      <h1 class="section-title">Retrieve profile info</h1>
      <p class="mt-4"><b>This method is only available in API v4.</b></p>
      <p><b>Scope:</b><i> profile</i></p>
      <hr class="line-break">
      <p>To retrieve user's profile info, send a request to the <i>profile</i> endpoint:</p>
      <v-card>
        <div class="card-body mb-4">
  <pre><code>POST /4/profile HTTP/1.1
   Host: api.signhero.io
   Content-Type: application/json

   {
       "action_str": "retrieve",
       "data_type": "profile",
       "trans_map": {
           "access_token": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
       },
       "request_map": {}
   }
  </code></pre>
        </div>
      </v-card>
      <v-card>
        <div class="card-body mb-4">
  <pre><code>{
      "action_str": "retrieved",
      "data_type": "profile",
      "log_list": [
          {
              "code_key": 200,
              "code_str": "ok",
              "user_msg": "profile retrieved",
              "level_int": 1,
              "level_str": "info"
          }
      ],
      "trans_map": {},
      "response_map": {
          "name": "John Doe",
          "email": "john.doe@mailprovider.com",
          "locale": "en",
          "is_admin": true,
          "organisation": "John Doe Inc."
      }
  }
  </code></pre>
        </div>
      </v-card>
      <p>The parameters in the response map:</p>
      <v-simple-table light>
        <template v-slot:default>
          <tbody>
          <tr>
            <td>name</td>
            <td>Name of the user.</td>
          </tr>
          <tr>
            <td>email</td>
            <td>Email address of the user.</td>
          </tr>
          <tr>
            <td>locale</td>
            <td>Communication language preference. Can be <b>en</b>, <b>fi</b>, <b>sv</b>,  <b>pl</b>, <b>de</b>, <b>nl</b>,  <b>fr</b>, <b>es</b> or <b>th</b>.</td>
          </tr>
          <tr>
            <td>is_admin</td>
            <td>Shows whether the user has admin rights.</td>
          </tr>
          <tr>
            <td>organisation</td>
            <td>Organisation name of the user.</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
    export default {
        name : "RetrieveProfileInfo"
    }
</script>

<style scoped>

</style>
