<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section13" class="UploadDocument sectionContainer">
      <h1 class="section-title">Upload a document</h1>
      <div class="d-flex version-switch mt-4">
        <span>v3</span>
        <v-switch v-model="isV4" class="mx-2"></v-switch>
        <span>v4</span>
      </div>
      <p><b>Scope:</b><i> basic</i></p>
      <hr class="line-break">
      <div v-if="!isV4">
        <p>To upload a document post a multipart/form-data request to the documents endpoint. The
          multi-part request needs to include a <b>payload_data</b> field, which contains the document to
          sign, and an <b>operation_data</b> field, which contains the JSON-Pure object:</p>
        <v-card>
          <div class="card-body">
<pre><code>curl -X POST \
    https://api.signhero.io/documents \
    -F 'operation_data={"action_str":"create","data_type":"document",
        "trans_map ":{"auth_key":"YYWMtGeygaKCEeahbgX148SD2wAAAVipR"},
        "request_map":{}}' \
    -F 'payload_data=@/Users/signhero/test.pdf;type=application/pdf'
</code></pre>
          </div>
        </v-card>
        <p>The maximum size for a document is 10MB.</p>
        <p>The successful response includes the identifier of the document:</p>
        <v-card>
          <div class="card-body">
<pre><code>{
    "action_str": "created",
    "data_type": "document",
    "log_list": [
        {
            "code_key": "200",
            "code_str": "ok",
            "user_msg": "document created",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "trans_map": {
        "auth_key": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
    },
    "response_map": {
        "document_id": "d807b62c-08bb-11e7-a142-080027334cc4"
    }
}
</code></pre>
          </div>
        </v-card>
      </div>
      <div v-if="isV4">
        <p>To upload a document post it to the document upload endpoint:</p>
        <v-card>
          <div class="card-body mb-4">
<pre><code>curl --X POST 'https://api.signhero.io/4/documents/upload?filename=test.pdf' \
 > --header 'Content-Type: application/pdf' \
 > --header 'Authorization: Bearer YYWMtGeygaKCEeahbgX148SD2wAAAVipR' \
 > --data-binary '@/Users/signhero/test.pdf'
</code></pre>
          </div>
        </v-card>
        <p>URL query parameters:</p>

        <v-simple-table light>
          <template v-slot:default>
            <tbody>
            <tr>
              <td>file_name</td>
              <td>Name of the document file in format <i>*.pdf</i>.</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="mt-4">Headers:</p>
        <v-simple-table light>
          <template v-slot:default>
            <tbody>
            <tr>
              <td>Content-Type</td>
              <td>application/pdf</td>
            </tr>
            <tr>
              <td>Content-Length</td>
              <td>Requests with chunked transfer encoding (e.g. those with streamed bodies) don't typically have
                <i>Content-Length</i> header appended automatically. Make sure to add it manually, if your request doesn't
                contain it.</td>
            </tr>
            <tr>
              <td>Authorization</td>
              <td>Access token in format <i>{{ authorizationContent }}</i></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="mt-4">The maximum size for a document is 10MB.</p>
        <p>The successful response includes the identifier of the document and its name:</p>
        <v-card>
          <div class="card-body">
<pre><code>{
    "document_id": "d807b62c-08bb-11e7-a142-080027334cc4",
    "file_name": "test.pdf"
}
</code></pre>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name : "UploadADocument",
        data()
        {
            return {
                isV4 : true,
                authorizationContent : 'Bearer {{access_token}}'
            }
        }
    }
</script>

<style scoped>

</style>
