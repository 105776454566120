<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section10" class="RefreshAccessToken sectionContainer ">
      <h1 class="section-title">Refresh an access token</h1>
      <div class="d-flex version-switch mt-4">
        <span>v3</span>
        <v-switch v-model="isV4" class="mx-2"></v-switch>
        <span>v4</span>
      </div>
      <hr class="line-break">
      <p>To refresh an access token, send a request to the token endpoint:</p>
      <v-card>
        <div class="card-body mb-4">
<pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/token HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "update",
     "data_type": "access_token",
     "request_map": {
         "client_id": "5e5b2260-d3a7-11e8-a608-92030a9483f4",
         "client_secret": "qSHofvOzKlAI7kYCNW3CmleZxmYF5nUiG1RZayDJsM",
         "grant_type": "refresh_token",
         "refresh_token": "j06Bv5oRTByT5GYiZOuUH4hqm8ZQzaXMdI4XYj1vY"
     }
 }
</code></pre>
        </div>
      </v-card>
      <p>The parameters in the request map:</p>
      <v-simple-table light>
        <template v-slot:default>
          <tbody>
          <tr>
            <td>client_id</td>
            <td>Your application's client identifier.</td>
          </tr>
          <tr>
            <td>client_secret</td>
            <td>Your application's client secret.</td>
          </tr>
          <tr>
            <td>grant_type</td>
            <td>The value must be "refresh_token".</td>
          </tr>
          <tr>
            <td>refresh_token</td>
            <td>The refresh token issued to your application.</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p class="mt-6">On success the response includes a new access token and refresh token:</p>
      <v-card>
        <div class="card-body">
<pre><code>{
    "action_str": "updated",
    "data_type": "access_token",
    "log_list": [
        {
            "code_key": <span v-if="!isV4">{{ versionDifferenceQuotationMark200 }}</span><span v-if="isV4">{{ versionDifference200 }}</span>,
            "code_str": "ok",
            "user_msg": "access_token updated",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "response_map": {},
    "trans_map": {
        "access_token": "KZ8oUeAYGjz6HSJRlI8O5zaVKRddXW34tbfbsCKqyKk",
        "expires": 1542376889609,
        "refresh_token": "7gzokMZKMbcJtJIe2XULggUAIiEFDIRJuHwFU8cGE"
    }
}
</code></pre>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
    export default {
        name : "RefreshAnAccessToken",
        data()
        {
            return {
                isV4 : true,
                versionDifferenceSlash4 : '/4',
                versionDifference200 : '200',
                versionDifferenceQuotationMark200 : '"200"',
            }
        }
    }
</script>

<style scoped>

</style>
