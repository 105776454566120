<template>
  <v-app>
    <v-app-bar dark color="grey darken-4" app>
      <v-toolbar-title>
        <a href="/">
          <img src="/signherologoW.svg" height="50" class="signhero-logo"/>
        </a>
      </v-toolbar-title>
      <v-spacer/>
      <v-btn class="ma-2 hidden-xs-only"
             v-for="item in items"
             active-class="pink"
             :input-value="isActive( item.path )"
             :key="item.path"
             :href="item.path"
             :ripple="false">
        <v-icon>{{ item.icon }}</v-icon>
        {{ item.label }}
      </v-btn>
      <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="shio-language-button" :ripple="false" @click="showLanguageSetting">
          <v-icon>
            mdi-earth
          </v-icon>
          <span class="hidden-sm-and-down">&nbsp;{{ $t( 'message.ThisLocale' ) }}</span>
        </v-btn>
      </template>
      </v-menu>
    </v-app-bar>
    <v-main app>
      <v-layout align-center justify-center>
        <v-container>
          <v-card flat>
            <div class="text-left" v-html="page_content" v-if="!choose_language_setting"></div>
            <v-row v-if="choose_language_setting" class="fill-height" no-gutters>
              <v-col>
                <div class="ma-auto mt-1 maxWidth-1100 pa-6">
                  <h1 class="shio-section-title text-left">{{ $t( 'message.Locales' ) }}</h1>
                  <v-radio-group v-model="locale" class="mx-3 mt-6">
                    <v-radio v-for="locale in locales" :key="locale" :label="$t( 'message.locale!' + locale )" :value="locale"></v-radio>
                  </v-radio-group>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <div class="text-left mt-2 mx-3">
                        <v-btn class="primary-btn choose-language-btn" @click="setLocale( locale )">{{ $t( 'signflow.Ok' ) }}</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
    import util from "../../util/util";
    const locales = [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ];
    export default {
        name : 'doc-card',
        props : {
            contentPath : String,
        },
        components : {
        },
        data()
        {
            const search = window.location.search.substring( 1 ).split( '&' );
            let signerToken;
            let locale;
            for( let i = 0; i < search.length; i++ )
            {
                const param = search[ i ].split( '=' );
                if( param[ 0 ] === "token" )
                {
                    signerToken = param[ 1 ];
                }
                else if( param[ 0 ] === "locale" )
                {
                    locale = param[ 1 ];
                }
            }
            return {
                items : [
                {
                    icon : "mdi-handshake",
                    path : "/terms-of-use.html",
                    label : this.$t( 'message.TermsOfUse' )
                },
                {
                    icon : "mdi-eye-off",
                    path : "/privacy-policy.html",
                    label : this.$t( 'message.PrivacyPolicy' )
                },
                {
                    icon : "mdi-cake-variant",
                    path : "/third-party.html",
                    label : this.$t( 'message.ThirdParty' )
                } ],
                page_content : require( "raw-loader!../../docs/homepage/" + ( locale || util.getLocale() ) + this.contentPath ).default,
                curLocale : locale || util.getLocale(),
                locales : locales,
                signerToken : signerToken,
                downloadBaseUrl : process.env.VUE_APP_PDFSIGN_DOWNLOAD_URL,
                choose_language_setting : false,
                locale : util.getLocale(),
            }
        },
        methods : {
            isActive( path )
            {
                return window.location.pathname.indexOf( path ) === 0;
            },
            setLocale( newLoc )
            {
                util.setLocale( newLoc );
            },
            showLanguageSetting()
            {
              this.locale = util.getLocale();
              this.choose_language_setting = !this.choose_language_setting;
            }
        },
        mounted()
        {
            document.documentElement.setAttribute( 'lang', util.getLocale() );
        }
    }
</script>

<style lang="sass">
  @import "../../styles/style"

  #app
    font-family: 'Ubuntu', sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    text-align: center
    color: $text-black

  html
    overflow-y: auto

  h1, h2, h3
    text-align: left

  .vdp-message
    white-space: pre-wrap

  .sh-licensesContainer
    font-size: 16px
    line-height: 1.5
    margin: auto
    padding: 30px
    overflow-y: hidden
    max-width: 1170px
    color: $text-black

  .sh-licensesContainer h4
    color: $vivid-pink
    font-weight: 400
    font-size: 24px
    margin-top: 40px
    margin-bottom: 25px

  .ma-2.hidden-xs-only.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--dark.v-size--default
    text-transform: none !important

  .ma-2.hidden-xs-only.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--dark.v-size--default:hover
    background-color: $vivid-pink !important

</style>
