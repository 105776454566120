<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section15" class="DeleteUnattachedDocument sectionContainer ">
      <h1 class="section-title">Delete an unattached document</h1>
      <p class="mt-4"><b>This method is only available in API v4.</b></p>
      <p><b>Scope:</b><i> basic</i></p>
      <hr class="line-break">
      <p>Before a document has been attached to a signing process, it can be deleted from SignHero. To delete a
        document, send a request to the <i>documents</i> endpoint:</p>
      <v-card>
        <div class="card-body mb-4">
<pre><code>POST /4/documents HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "delete",
     "data_type": "document",
     "trans_map": {
         "access_token": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
     },
     "request_map": {}
 }
</code></pre>
        </div>
      </v-card>
      <v-card>
        <div class="card-body">
<pre><code>{
    "action_str": "deleted",
    "data_type": "document",
    "log_list": [
        {
            "code_key": 200,
            "code_str": "ok",
            "user_msg": "document deleted",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "trans_map": {},
    "response_map": {}
}
</code></pre>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
    export default {
        name : "DeleteAnUnattachedDocument"
    }
</script>

<style scoped>

</style>
