<template>
  <div class="maxWidth-850 ma-auto text-left py-8">
    <v-card flat v-if="!is_starting && !has_completed">
      <p class="sh-sign-greeting px-4 mb-6">{{ $t( 'pdfsign.SignerGreetings' ) }} {{ sign_event.signerName }}!</p>
      <h2 class="shio-section-title">{{ $t( 'pdfsign.WhoSigns' ) }}</h2>
      <div class="my-6 pb-2 px-4" v-for="signer in flow_data.signers">
        <span class="sh-signer-name">{{ signer.signerName }}</span>
        <span v-if="sign_event.signerEmail === signer.signerEmail">&nbsp;({{ $t( 'pdfsign.You' ) }})</span>
      </div>
      <h2 class="shio-section-title" v-html="$t( 'pdfsign.WhatSigned' )"></h2>
      <p class="my-6 px-4 documents-name-list">
        <span v-for="document in flow_data.documents">
          <v-icon>mdi-file-pdf-box</v-icon> <a :href="downloadLink( document )" class="pl-1 pdf-link" target="_blank"
                                               rel="noopener">{{ document.fileName }}</a>
          <br/>
        </span>
      </p>
      <v-divider class="ma-2"/>
      <div v-html="$t( 'pdfsign.UsagePolicyInfoMsg' )" class="mt-6 px-6"></div>
      <div class="tick-box">
        <v-checkbox v-model="is_accepted" :label="$t( 'pdfsign.SignConfirmationMsg' )" class="px-6"/>
      </div>
      <v-row class="mt-4" no-gutters>
        <v-col cols="12" sm="4" class="pa-2">
          <v-btn block @click="preDecline()" class="alternative-primary-btn py-5 ma-auto">{{ $t( 'pdfsign.Decline' ) }}</v-btn>
        </v-col>
        <v-col class="hidden-xs-only">
          <v-spacer/>
        </v-col>
        <v-col v-if="!enabledMethods.signature || enabledMethods.signature.length === 0" cols="12" sm="4" class="pa-2">
          <v-btn block @click="sign()" :disabled="!is_accepted" class="primary-btn py-5 ma-auto">{{ $t( 'pdfsign.Sign' ) }}</v-btn>
        </v-col>
        <v-col v-if="enabledMethods.signature && enabledMethods.signature.length > 0" cols="12" sm="4" class="pa-2">
          <v-btn block @click="advanced_sign()" :disabled="!is_accepted" class="primary-btn py-5 ma-auto">{{ $t( 'pdfsign.AdvancedSign' ) }}</v-btn>
        </v-col>
      </v-row>
      <div v-if="authenticationType !== 'access_token'">
        <v-divider class="ma-2"/>
        <v-row class="mt-4" no-gutters>
          <v-col cols="12" sm="4" class="pa-2">
            <v-btn block @click="logout()" class="alternative-primary-btn py-5 ma-auto">{{ $t( 'signedin.Logout' ) }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card flat class="pa-2" v-if="has_completed">
      <v-card-text justify="center" class="text-center">
        <h1 class="text-center black--text pt-3 completion-title" v-if="!sign_failed">{{ completion_title }}</h1>
        <v-img v-if="is_accepted" contain max-height="100" class="my-10" src="/check.png"></v-img>
        <v-img v-if="is_declined" contain max-height="100" class="my-10" src="/decline.png"></v-img>
        <div class="sh-completion-message my-10" v-html="completion_message"></div>
        <div v-if="is_failed || has_signed_out" class="reasons-explanation">
          <not-found-card :hide-reasons="sign_failed || has_signed_out"/>
        </div>
      </v-card-text>
    </v-card>
    <v-overlay v-if="is_working || is_starting">
      <div>
        <v-progress-circular size="48" indeterminate/>
        <div class="sh-just-a-sec">{{ $t( 'message.JustASec' ) }}</div>
      </div>
    </v-overlay>
    <signhero-dialog :message="dialogMessage" :resolve="decline" type="prompt" v-if="showPromptDialog" ></signhero-dialog>
    <v-dialog v-model="chooseMethodDialog" width="auto">
      <div class="container">
        <v-card style="max-width: 900px;">
          <v-card-title>
            <b>{{ $t( 'pdfsign.chooseMethodDialogTitle' ) }}</b>
            <v-spacer/>
            <v-icon @click="advanced_sign()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <p>
            </p>
            <eideasy-widget
                :language="locale"
                :sandbox="eideasySandbox"
                :doc-id= "sign_event.eideasy_doc_id"
                :client-id="eideasyClientId"
                :enabled-methods.prop="enabledMethods"
                :on-success.prop="advancedSigningSuccess"
                :on-fail.prop="advancedSigningFail"
            />
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
    import NotFoundCard from "./../common/NotFoundCard"
    import util from "../../util/util";
    import SignHeroDialog from "../../util/SignHeroDialog";
    import '@eid-easy/eideasy-widget';

    export default {
        name : "sh-signing-card",
        components : {
            "not-found-card" : NotFoundCard,
            "signhero-dialog" : SignHeroDialog
        },
        props : {
            authenticationType : String,
            signerToken : String,
            downloadBaseUrl : String,
            groupId : String,
            invitedGroupId : String,
            processUuid : String,
            eventUuid : String,
            autoClose : Boolean
        },
        data()
        {
            return {
                from : "",
                is_starting : true,
                is_working : false,
                is_accepted : false,
                is_failed : false,
                is_declined : false,
                sign_failed : false,
                has_completed : false,
                has_signed_out : false,
                completion_title : "",
                completion_message : "",
                flow_data : {},
                sign_event : {},
                dialogMessage : {
                    title : this.$t( 'pdfsign.DeclineToSignTitle' ),
                    text : this.$t( 'pdfsign.DeclinationPlaceHolder' ),
                    acceptText : this.$t( 'pdfsign.Decline' ),
                    cancelText : this.$t( 'pdfsign.Cancel' )
                },
                showPromptDialog : false,
                chooseMethodDialog : false,
                eideasySandbox : process.env.VUE_APP_EIDEASY_WIDGET_SANDBOX,
                eideasyClientId : process.env.VUE_APP_EIDEASY_CLIEND_ID,
                locale: util.getLocale(),
                enabledMethods: {}
            }
        },
        methods : {
            /**
             * Download URL for a document.
             *
             * @param doc
             * @returns {string}
             */
            downloadLink( doc )
            {
                return `${this.downloadBaseUrl}${this.flow_data.groupId}/${this.flow_data.uuid}/${doc.uuid}/${doc.fileName}`
            },
            /**
             * Pre decline process.
             */
            preDecline()
            {
                this.showPromptDialog = true;
            },
            /**
             * If confirmed by user, decline to sign and handle result.
             */
            decline( reasons )
            {
                this.showPromptDialog = false;
                if( reasons === false )
                {
                    return;
                }
                this.is_working = true;
                this.$store.pdfSignClient.abortSignature( this.flow_data.groupId,
                this.flow_data.uuid,
                this.sign_event.uuid,
                reasons ).then( r =>
                {
                    this.is_working = false;
                    this._handleDecline();
                } ).catch( e => this._handleError( this.$t( 'pdfsign.Error!Unexpected' ) ) );
            },
            /**
             * Sign, then poll status and handle success or error.
             */
            sign()
            {
                this.is_working = true;
                this.$store.pdfSignClient.createSignature( this.flow_data.groupId,
                this.flow_data.uuid,
                this.sign_event.uuid ).then( r =>
                {
                    this._pollStatus().then( () =>
                    {
                        this._handleSuccess();
                    } ).catch( e =>
                    {
                        this.is_accepted = false;
                        this.sign_failed = true;
                        this._handleError( this.$t( 'pdfsign.Error!Unexpected' ) );
                    } );
                } ).catch( e =>
                {
                    if( e.code_key === 409 )
                    {
                        this._handleError( this.$t( 'pdfsign.CompleteProcessInfo' ) );
                    }
                    else if( e.code_key === 400 )
                    {
                        this.sign_failed = true;
                        this.is_accepted = false;
                        this._handleError( this.$t( 'pdfsign.SessionNeedRefreshError' ) );
                    }
                    else
                    {
                        this.sign_failed = true;
                        this._handleError( this.$t( 'pdfsign.Error!Unexpected' ) );
                    }
                } );
            },
            /**
             * This function gets called when signing succeeds.
             *
             * @param data
             */
            advancedSigningSuccess( data )
            {
                this.chooseMethodDialog = !this.chooseMethodDialog;
                this.is_working = true;
                this._pollStatus().then( () =>
                {
                    this._handleSuccess();
                } ).catch( e =>
                {
                    this.is_accepted = false;
                    this.sign_failed = true;
                    this._handleError( this.$t( 'pdfsign.Error!Unexpected' ) );
                } );
            },
            /**
             * This function gets called when signing fails.
             * @param error
             */
            advancedSigningFail( error )
            {
                this._handleError( this.$t( 'pdfsign.Error!Unexpected' ) );
            },
            /**
             * Show choose method dialog when user click advanced sign.
             */
            advanced_sign()
            {
                this.chooseMethodDialog = !this.chooseMethodDialog;
            },
            /**
             * Set has_signed_out flag and ._terminate with appropriate messages.
             */
            logout()
            {
                this.has_signed_out = true;
                this._terminate( this.$t( 'message.SeeYouSoon' ), this.$t( 'message.HowToOpenExpiredSession' ) );
            },
            /**
             * Poll status of current signature event. Resolve if completed, reject if failed, retry in a while if
             * neither.
             *
             * @returns {Promise<unknown>}
             * @private
             */
            _pollStatus()
            {
                return new Promise( ( resolve, reject ) =>
                {
                    setTimeout( () =>
                    {
                        this.$store.pdfSignClient.retrieveStatus( this.flow_data.groupId,
                        this.flow_data.uuid,
                        this.sign_event.uuid ).then( r =>
                        {
                            switch( r.eventStatus )
                            {
                                case "completed" :
                                    resolve();
                                    break;
                                case "failed" :
                                    reject();
                                    break;
                                default :
                                    this._pollStatus().then( () => resolve() ).catch( e => reject() );
                            }
                        } ).catch( e => reject( e ) );
                    }, 2000 );
                } );
            },
            /**
             * Set is_accepted flag and ._terminate with appropriate messages.
             *
             * @private
             */
            _handleSuccess()
            {
                this.is_accepted = true;
                this._terminate( "", this.$t( 'pdfsign.SignedAndSent' ), false );
            },
            /**
             * Set is_accepted flag and ._terminate with appropriate messages.
             *
             * @private
             */
            _handleDecline()
            {
                this.is_accepted = false;
                this.is_declined = true;
                this._terminate( this.$t( 'pdfsign.SignEventCancelled' ), this.$t( 'pdfsign.ThankUsingService' ) + '<br/><br/>' + this.$t( 'pdfsign.FindMoreInformation' ), true );
            },
            /**
             * Set is_failed flag and ._terminate with appropriate messages.
             *
             * @private
             */
            _handleError( msg )
            {
                this.is_failed = true;
                this.is_working = false;
                this._terminate( this.$t( 'pdfsign.SorryUnexpectedError' ), msg );
            },
            /**
             * For signed-in (access_token) users, show an alert or snackbar (if set) with pdfsign. For others, set
             * flags to so state is terminated, and display completion pdfsign.
             *
             * @param title
             * @param msg
             * @param snackbar
             * @private
             */
            _terminate( title, msg, snackbar )
            {
                const acceptText = this.$t( "signflow.Ok" );
                if( this.authenticationType === "access_token" )
                {
                    if( snackbar )
                    {
                        this.$parent.$parent.$inform( title );
                        this.$router.replace( "/start" );
                    }
                    else
                    {
                        this.$alert( { title : title, text : msg, acceptText : acceptText } ).then( () =>
                        {
                            this.$router.replace( "/start" );
                        } );
                    }
                }
                else
                {
                    this.is_starting = false;
                    this.is_working = false;
                    this.has_completed = true;
                    this.completion_title = title;
                    this.completion_message = msg;
                    this.$store.signSessionClient.deleteSignerTokenSession( this.is_failed || this.has_signed_out,
                    this.flow_data.uuid, this.sign_event.uuid ).then( r => {} ).catch( e => {} );
                    if ( this.autoClose )
                    {
                        setTimeout( () => window.close(), 2000 );
                    }
                }
            }
        },
        /**
         * Create a signing session (type depends on .authenticationType). Then retrieve the the sign flow and sign
         * event and set state to ready.
         */
        mounted()
        {
            let createSession;
            if( this.authenticationType === "access_token" )
            {
                createSession =
                this.$store.signSessionClient.createAccessTokenSession( this.groupId,
                this.invitedGroupId || "",
                this.processUuid,
                this.eventUuid );
            }
            else if( !this.signerToken )
            {
                createSession = this.$store.signSessionClient.retrieveSignerTokenSession();
            }
            else
            {
                if( history.replaceState )
                {
                    history.replaceState( null, null, "?locale=" + util.getLocale() );
                }
                createSession = this.$store.signSessionClient.createSignerTokenSession( this.signerToken, "sign" );
            }
            createSession.then( r =>
            {
                this.$store.pdfSignClient.retrieveSignFlow( r.groupId, r.processUuid, r.eventUuid ).then( r =>
                {
                    this.flow_data = r.process;
                    this.sign_event = r.signEvent;
                    this.is_starting = false;
                    this.enabledMethods.signature = this.sign_event.allowedSignatureTypes;
                } ).catch( e => this._handleError( this.$t( 'pdfsign.Error!Unexpected' ) ) );
            } ).catch( e =>
            {
                switch( e.code_key )
                {
                    case 409 :
                        this._handleError( this.$t( 'pdfsign.CompleteProcessInfo' ) );
                        break;
                    case 401 :
                        this._handleError( this.$t( 'pdfsign.ReasonExpiredSession' ) );
                        break;
                    default :
                        this._handleError( this.$t( 'pdfsign.InvalidLoginUrl' ) );
                }
            } );
        }
    }
</script>

<style lang="sass">
  @import "../../styles/style"

  .sh-sign-greeting
    font-size: 20px

  .sh-signer-name
    font-weight: bold

  .sh-completion-message
    font-size: 22px
    color: $text-black

  /**
   * This comes from a localisation.
   */

  span.sh-nowOrNeverText
    font-size: 15px
    font-weight: normal
    font-style: italic
    color: gray

  .maxWidth-850
    max-width: 850px

  .pdf-link
    color: $vivid-pink

  .tick-box i
    color: $vivid-pink !important

  .completion-title
    font-size: 24px

  .v-dialog.v-dialog--active.v-dialog--persistent
    width: inherit
</style>
