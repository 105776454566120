<template>
  <v-container fluid class="fill-height pa-0">
    <div class="content text-left ma-auto mr-0">
      <div id="section4" class="sectionContainer">
        <h1 class="section-title">Revoke tokens</h1>
        <div class="d-flex version-switch mt-4">
          <span>v3</span>
          <v-switch v-model="isV4" class="mx-2"></v-switch>
          <span>v4</span>
        </div>
        <hr class="line-break">
        <p>To disable your current access token, send a request to the authentication endpoint:</p>
        <v-card class="mb-4">
          <div class="card-body">
<pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/auth HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str" : "delete",
     "data_type" : "access_token",
     "trans_map": {
        "<span v-if="isV4">{{ versionDifferenceAccessToken }}</span><span v-if="!isV4">{{ versionDifferenceAuthKey }}</span>": "YWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
     },
     "request_map": {}
 }
</code></pre>
          </div>
        </v-card>
        <v-card class="mb-4">
          <div class="card-body">
<pre><code>{
    "action_str": "deleted",
    "data_type": "access_token",
    "log_list": [
        {
            "code_key": <span v-if="isV4">{{ versionDifference200 }}</span><span v-if="!isV4">{{ versionDifferenceQuotationMark200 }}</span>,
            "code_str": "ok",
            "user_msg": "access_token deleted",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "response_map": {},
    "trans_map": {}
}
</code></pre>
          </div>
        </v-card>
        <p>You can also disable all your access tokens with one request:</p>
        <v-card class="mb-4">
          <div class="card-body">
<pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/auth HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "delete",
     "data_type": "access_tokens",
     "trans_map": {
         "<span v-if="isV4">{{ versionDifferenceAccessToken }}</span><span v-if="!isV4">{{ versionDifferenceAuthKey }}</span>": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
     },
     "request_map": {}
 }
</code></pre>
          </div>
        </v-card>
        <v-card>
          <div class="card-body">
<pre><code>{
    "action_str": "deleted",
    "data_type": "access_tokens",
    "log_list": [
        {
            "code_key": <span v-if="isV4">{{ versionDifference200 }}</span><span v-if="!isV4">{{ versionDifferenceQuotationMark200 }}</span>,
            "code_str": "ok",
            "user_msg": "access_tokens deleted",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "response_map": {},
    "trans_map": {}
}
</code></pre>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
    export default {
        name : "ClientCredentialsRevokeTokens",
        data()
        {
            return {
                drawer : true,
                color : false,
                isV4 : true,
                versionDifferenceSlash4 : '/4',
                versionDifference200 : '200',
                versionDifferenceQuotationMark200 : '"200"',
                versionDifferenceAuthKey : 'auth_key',
                versionDifferenceAccessToken : 'access_token',
            }
        }
    }
</script>

<style lang="sass" scoped>

</style>
