import Vue from "vue";
import VueI18n from "vue-i18n";
import util from "../util/util";
import en from "./en/shio-core";
import { cloudcoreI18n } from "@cloudlace/vue-widgets";
const locale = util.getLocale();
const fallbackLocale = "en";
Vue.use( VueI18n );

const dateTimeFormats = {
    'en': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    }
};

const i18n = new VueI18n( {
    locale : locale,
    fallbackLocale : fallbackLocale,
    dateTimeFormats : dateTimeFormats,
    messages : {
        en : en,
    }
} );
i18n.mergeLocaleMessage( locale, require( "./" + locale + "/shio-core" ).default );
i18n.mergeLocaleMessage( locale, cloudcoreI18n[ locale ] );




window.$cc_ui_locale = locale;
export default i18n;