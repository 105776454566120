const uuidRegExp = "[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}";
const provider = {};
export default {
    "interface_version" : "2.0.0",
    "create" : {
        "signer_token_session" : {
            "handler" : [ provider, "createSignerTokenSession" ],
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "signerToken" : { "type" : "string" },
                    "scope" : { "type" : "string", "enum" : [ "sign", "download" ] }
                },
                "additionalProperties" : false,
                "required" : [ "signerToken", "scope" ]
            }
        }
    },
    "retrieve" : {
        "signer_token_session" : {
            "handler" : "route"
        }
    },
    "delete" : {
        "signer_token_session" : {
            "handler" : [ provider, "deleteSignerTokenSession" ],
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "signingFail" : { "type" : "boolean" },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "eventUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false
            }
        }
    }
};
