<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section18" class="RetrieveSigningProcess sectionContainer ">
      <h1 class="section-title">Retrieve a signing process</h1>
      <div class="d-flex version-switch mt-4">
        <span>v3</span>
        <v-switch v-model="isV4" class="mx-2"></v-switch>
        <span>v4</span>
      </div>
      <p><b>Scope:</b><i> basic</i></p>
      <hr class="line-break">
      <p>Retrieves the details and status of a signing process:</p>
      <v-card>
        <div class="card-body mb-4">
<pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/signatures HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "retrieve",
     "data_type": "signature_flow",
     "trans_map": {
         "<span v-if="isV4">{{ versionDifferenceAccessToken }}</span><span
      v-if="!isV4">{{ versionDifferenceAuthKey }}</span>": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
     },
     "request_map": {
         "process_id": "d7e93169-08bb-11e7-a142-080027334cc4"
     }
 }
</code></pre>
        </div>
      </v-card>
      <v-card>
        <div class="card-body mb-4">
<pre><code>{
    "action_str": "retrieved",
    "data_type": "signature_flow",
    "log_list": [
        {
            "code_key": <span v-if="!isV4">{{ versionDifferenceQuotationMark200 }}</span><span v-if="isV4">{{ versionDifference200 }}</span>,
            "code_str": "ok",
            "user_msg": "signature_flow retrieved",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "trans_map": {<span v-if="!isV4">
        "auth_key": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
    </span>},
    "response_map": {
        "uuid" : "d7e93169-08bb-11e7-a142-080027334cc4",
        "title": "My first",
        "created": 1488807723074,
        "modified": 1488807859249,
        "status": "completed",
        "pending_count": 0,
        "signature_invitation": {
            "subject": "Signature invitation",
            "message": "Your signature is requested."
        },
        "locale": "en",
        "documents": [
            {
                "uuid": "d807b62c-08bb-11e7-a142-080027334cc4",
                "file_name": "test.pdf"
            }
        ],
        "signers": [
            {
                "uuid": "2d871e79-0b23-11e7-93dc-42067774ef10",
                "name": "SignHero Tester",
                "email": "signhero.tester@signhero.io",
                "locale": "en",
                "embed": false,
                "status": "signed",
                "signed": 1488807859156
            }
        ],
        "completed": 1488807859156
    }
}
</code></pre>
        </div>
      </v-card>
      <p>The parameters in the response map:</p>
      <v-simple-table light>
        <template v-slot:default>
          <tbody>
          <tr>
            <td>created</td>
            <td>Time when the signing process was created.</td>
          </tr>
          <tr>
            <td>modified</td>
            <td>Time when the signing process was last modified.</td>
          </tr>
          <tr>
            <td>completed</td>
            <td>Time when the signing process was completed.</td>
          </tr>
          <tr>
            <td>canceled</td>
            <td>Time when the signing process was canceled.</td>
          </tr>
          <tr>
            <td>status</td>
            <td class="py-3">Status of the signing process:
              <ul>
                <li><b>pending</b>: There is pending signatures.</li>
                <li><b>completed</b>: All signers have signed.</li>
                <li><b>canceled</b>: The signing process has been canceled or someone declined to sign.
                </li>
                <li><b>inactive</b>: Process creation is ongoing. After this the status will be pending
                  or failed.
                </li>
                <li><b>failed</b>: Process creation failed. All failed signing processes are deleted
                  within 24 hours.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>pending_count</td>
            <td>Number of pending signatures.</td>
          </tr>
          <tr>
            <td>documents</td>
            <td>A list of the documents sent for signature.</td>
          </tr>
          <tr>
            <td>signers</td>
            <td>A list of the signers.</td>
          </tr>
          <tr>
            <td>signer:status</td>
            <td>Status of the signer: pending, signed or declined.</td>
          </tr>
          <tr>
            <td>signer:signed</td>
            <td>Time when the signer signed the documents.</td>
          </tr>
          <tr>
            <td>signer:declined</td>
            <td>Time when the signer declined to sign.</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
    export default {
        name : "RetrieveASigningProcess",
        data()
        {
            return {
                isV4 : true,
                versionDifferenceSlash4 : '/4',
                versionDifference200 : '200',
                versionDifferenceQuotationMark200 : '"200"',
                versionDifferenceAuthKey : 'auth_key',
                versionDifferenceAccessToken : 'access_token',
            }
        }
    }
</script>

<style scoped>

</style>
