<template>
  <v-container fluid class="fill-height pa-0">
    <div class="content text-left ma-auto mr-0">
      <div id="section3" class="ClientCredentials sectionContainer">
        <h1 class="section-title">Authorisation grants - Client credentials</h1>
        <hr class="line-break">
        <p>Use this grant when your application needs to access its own processes.</p>
      </div>
    </div>
  </v-container>
</template>

<script>
    export default {
        name : "ClientCredentials",
        data()
        {
            return {
                drawer : true,

            }
        }
    }
</script>

<style lang="sass">
  .side-bar-sub-title
    font-size: 14px !important

  .v-list-item.v-list-item--link.theme--light.side-bar-sub-item
    padding-left: 30px !important

  .v-list-group__header.v-list-item.v-list-item--link.theme--light a
    padding-left: 0 !important
</style>
