export default {
  badge: 'Merkki',
  close: 'Sulje',
  dataIterator: {
    noResultsText: 'Kohteita ei löytynyt',
    loadingText: 'Ladataan kohteita...',
  },
  dataTable: {
    itemsPerPageText: 'Rivejä per sivu:',
    ariaLabel: {
      sortDescending: 'Järjestetty alenevasti.',
      sortAscending: 'Järjestetty nousevasti.',
      sortNone: 'Ei järjestetty.',
      activateNone: 'Aktivoi poistaaksesi järjestyksen.',
      activateDescending: 'Aktivoi järjestääksesi alenevasti.',
      activateAscending: 'Aktivoi järjestääksesi nousevasti.',
    },
    sortBy: 'Järjestä',
  },
  dataFooter: {
    itemsPerPageText: 'Kohteita per sivu:',
    itemsPerPageAll: 'Kaikki',
    nextPage: 'Seuraava sivu',
    prevPage: 'Edellinen sivu',
    firstPage: 'Ensimmäinen sivu',
    lastPage: 'Viimeinen sivu',
    pageText: '{0}-{1} / {2}',
  },
  datePicker: {
    itemsSelected: '{0} valittu',
  },
  noDataText: 'Ei tietoja',
  carousel: {
    prev: 'Edellinen dia',
    next: 'Seuraava dia',
    ariaLabel: {
      delimiter: 'Karusellin dia {0} / {1}',
    },
  },
  calendar: {
    moreEvents: '{0} lisää',
  },
  fileInput: {
    counter: '{0} tiedostoa',
    counterSize: '{0} tiedostoa (yhteensä {1})',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
}
