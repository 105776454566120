<template>
  <v-app>
    <v-app-bar class="subpages-header" app>
      <v-toolbar-title>
        <a href="/">
          <img src="/signherologoW.svg" class="hidden-xs-only signhero-logo" height="50"/>
          <img src="/SHicon.svg" class="hidden-sm-and-up signhero-icon" height="50"/>
        </a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="white" text class="shio-language-button" :ripple="false" @click="showLanguageSetting">
            <v-icon>
              mdi-earth
            </v-icon>
            &nbsp;{{ $t( 'message.ThisLocale' ) }}
          </v-btn>
        </template>
      </v-menu>
    </v-app-bar>
    <v-main app>
      <v-layout align-center justify-center>
        <v-container>
          <v-flex>
            <div class="confirm-error-message" v-if="!choose_language_setting">
              <p v-if="isConfirmPage">{{ $t( 'message.ConfirmError1' ) }}<a class='elem-pink' href='https://www.signhero.io/#/signin'>{{ $t( 'message.SignHeroDotIo' ) }}</a>{{ $t( 'message.ConfirmError2' ) }}</p>
              <p v-if="!isConfirmPage" v-html="$t( 'message.ResetPWError' )"></p>
            </div>
            <v-row v-if="choose_language_setting" class="fill-height" no-gutters>
              <v-col>
                <div class="ma-auto mt-1 maxWidth-1100 pa-6">
                  <h1 class="shio-section-title text-left">{{ $t( 'message.Locales' ) }}</h1>
                  <v-radio-group v-model="locale" class="mx-3 mt-6">
                    <v-radio v-for="locale in locales" :key="locale" :label="$t( 'message.locale!' + locale )" :value="locale"></v-radio>
                  </v-radio-group>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <div class="text-left mt-2 mx-3">
                        <v-btn class="primary-btn choose-language-btn" @click="setLocale( locale )">{{ $t( 'signflow.Ok' ) }}</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-flex>
        </v-container>
      </v-layout>
    </v-main>
    <sh-app-footer/>
  </v-app>
</template>

<script>
    import AppFooter from "../../components/common/AppFooter";
    import util from "../../util/util";

    const locales = [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ];
    export default {
        name : "App",
        components : {
            "sh-app-footer" : AppFooter,
        },
        data() {
            const search = window.location.search.substring( 1 ).split( '&' );
            const isConfirmPage = window.location.pathname.startsWith( "/confirm" );

            let locale;
            for( let i = 0; i < search.length; i++ )
            {
                const param = search[ i ].split( '=' );
                if( param[ 0 ] === "locale" )
                {
                    locale = param[ 1 ];
                }
            }
            return {
                curLocale : locale || util.getLocale(),
                locales,
                isConfirmPage,
                choose_language_setting : false,
                locale : util.getLocale(),
            }
        },
        methods : {
            setLocale( newLoc )
            {
                util.setLocale( newLoc );
            },
            showLanguageSetting()
            {
              this.locale = util.getLocale();
              this.choose_language_setting = !this.choose_language_setting;
            }
        },
        mounted()
        {
            document.documentElement.setAttribute( 'lang', util.getLocale() );
        }
    }
</script>

<style lang="sass">
  @import "../../styles/style"

  .confirm-error-message p
    font-size: 22px
    margin-top: 60px
</style>
